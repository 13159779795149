// Accessibility Mixins

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

@mixin visually-hidden-important {
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  width: 1px !important;
  word-wrap: normal !important;
}

// Turns off the visually-hidden effect.
@mixin visually-hidden-off {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
}

@mixin visually-hidden-off-important {
  clip: auto !important;
  height: auto !important;
  overflow: visible !important;
  position: static !important;
  width: auto !important;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin focusable {
  @include visually-hidden;

  &:active,
  &:focus {
    @include visually-hidden-off;
  }
}

@mixin focusable-important {
  @include visually-hidden-important;

  &:active,
  &:focus {
    @include visually-hidden-off-important;
  }
}

// Makes an element completely hidden, visually and to screen readers.
@mixin hidden {
  display: none;
}

@mixin hidden-important {
  display: none !important;
}

// Makes an element completely hidden, visually and to screen readers, but
// maintains its layout.
@mixin invisible {
  visibility: hidden;
}

@mixin invisible-important {
  visibility: hidden !important;
}

@mixin transparent {
  @include opacity(0);
}

.transparent{
  @include opacity(0);
}

// The skip-link link will be completely hidden until a user tabs to the link.
.skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px;
    text-align: center;
    color: $white;
  }
}

// Removing focus from content so that skip-link fix does not always highlight main container when any content is clicked
.main-content,
#burger a{
  outline: none;
}
