// Footer

#footer {
  background: $bg_blue;
  color: #fff;
  padding: 40px 0 100px;
  @include breakpoint(tablet-plus){
    padding: 67px 0 78px;
  }

  a{
    color: #fff;
    text-decoration: none;
  }
  h3, h4{
    margin: 0;
    color: #fff;
    @include fontSize(15);
    margin-bottom: 10px;
    text-transform: uppercase;
  }


  .row{
    display: flex;
    justify-content: space-between;
    gap: 23px;
    max-width: 1140px;
    margin: 0 auto;

    @include breakpoint(tablet-plus){
      gap: 0;
    }
  }

  ul{
    padding: 0;
    margin: 0;
    li{
      list-style: none;
      margin-bottom: 10px;
      line-height: 150%;
      @include fontSize(15);
      @include breakpoint(desktop){
        @include fontSize(18);
        line-height: 150%;
      }
    }

    &.social-menu{
      li{
        margin: 0;
        float: left;
        a{
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          margin-right: 10px;
          @include breakpoint(tablet-plus){
            width: 20px;
            height: 20px;
            margin-right: 19px;
          }
          img{
            display: inline-block
          }
        }
      }
    }
  }

  .external-link{
    clear: both;
    display: block;
    margin-top: 3px;
    font-weight: 700;
    text-transform: uppercase;
    @include fontSize(13);
    float: left;
    &::after{
      content: '';
      width: 20px;
      height: 20px;
      background: url(#{$theme}/images/icons/external-link.svg) center center no-repeat transparent;
      background-size: contain;
      margin-left: 10px;
      float: right;
    }

    &.exter-link-blue{
      display: flex;
      align-items: center;
      @include fontSize(18);
      line-height: 150%;
      img{
        width: 74px;
        margin-right: 32px;
      }
    }
  }

  #block-block-4{
    display: none;
  }

  @include breakpoint(tablet-plus){

    .lidl-info{
      width: 315px;
      p{
        width: 100%;
        @include fontSize(15);
        line-height: 120%;
        float: left;
        margin-top: 30px;

      }
    }

    #block-menu-menu-footer-menu{
      width: 150px;
      margin: 0 4% 0 5.5%;
      @include breakpoint(desktop){
        margin: 0 4.4% 0 7%;
      }
      h3{
        display: none;
      }
    }

    .right-container{
      max-width: 125px;
      margin-right: 3%;
      @include breakpoint(desktop){
        margin-right: 6.4%;
      }
      h4{
        font-weight: 400;
        @include fontSize(15);
        line-height: 140%;

        @include breakpoint(desktop){
          @include fontSize(18);
          line-height: 140%;
        }
      }
    }
    .csr-spravy{
      flex: 1 1 0px;
      a{
        text-transform: uppercase;
        //margin-bottom: 40px;
        display: block;
        img{
          margin-top: 20px;
          width: 67%;
        }

        @include fontSize(15);
        line-height: 140%;

        @include breakpoint(desktop){
          @include fontSize(18);
          line-height: 140%;
        }

      }
    }
  }

}
