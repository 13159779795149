#section-csr-oblast{
    text-align: center;
    background: #F4F5E7;
    margin-top: 30px;


    .mid{
        max-width: 812px;
        margin: 0 auto;
        @include breakpoint(tablet-plus){
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
            text-align: left;
            .text-wrapper{
                max-width: 343px;
            }
        }
    }


    img{
        margin-top: 50px;
        @include breakpoint(tablet-plus){
            margin: 30px 0;
        }
    }
    .btn{
        margin: 30px auto 50px;
        display: inline-block;
        width: 222px;
        @include breakpoint(tablet-plus){
            width: 193px;
            display: block;
        }
    }
    p{
        color: $gray1;
        padding: 0 15px;
    }
}