#cookie{
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100%;
    background: $secondary;
    color:$white;
    font-size: 16px;
    text-align: center;
    @include breakpoint(tablet) {
        text-align: left;
    }
}

#cookie-text{
    width: 100%;
    padding: 25px 30px;
    box-sizing: border-box;
    a{
        color: $white;
    }
    @include breakpoint(tablet) {
        padding-right: 210px;
    }
}

#cookie-button{
        position: relative;
        margin: 0 auto 30px;
        text-align: center;
    a{
        width: 150px;
        height: 45px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 45px;
        color: #fff;
        border: 1px solid $white;
        margin: 0 auto;
        &:hover{
            border: 1px solid $primary;
            background: $white;
            color: $primary;
        }
    }

    @include breakpoint(tablet) {
        position: absolute;
        right: 30px;
        top: 50%;
        margin: -22px 0 0;
    }
}

