// Base Colors

$black: #000;
$black70: #000000AA;
$black00: #00000000;
$white: #fff;


$gray1: #333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #C5C4C5;
$gray5: #E0E0E0;
$gray6: #F2F2F2;

$ctext: #30495F;
$ctext_dark: #163046;
$text: $ctext_dark;

$blue: #005371;
$bg_blue: #013F5A;

$green: #B4BE5D;
$green2: #C9D461;

$green-bg:#ECEFD7;
$green3: #46712D;
$green15: rgba(180, 190, 93, .15);


$red: #EB5757;

$brown: #EEE4D7;

$primary: $green;
$secondary: $green2;

$disabled: #828282;

$highlight: #FFF3CD;
$highlight-border: #D2C6A0;

$success: #F5FBF6;
$success-border: #0AB21B;

$warning: #FAE1E1;
$warning-border: #FFA9A9;

$gradient: $primary, $secondary;
$gradient-light: rgba(255,255,255,.8) 0%, #ffffff00 59.62%;
$gradient-dark: rgba(0,0,0,.5) 0%, #00000000 59.62%;
$gradient-totop: #38363A 0%, rgba(57, 55, 59, 0) 100%;
$shadow: $black70, $black00;