//OZVDR a Lidl-srdcom

.page-node-571, .page-node-586{
    .field-collection-item-field-basic-sekcia{
        position: relative;
    }

    .field-name-field-headline-sekcie{
        position: relative;
        h2{
            @include fontSize(18);
            line-height: 140%;
            text-transform: uppercase;
            color: #30495F;
            font-weight: 400;
            background: #fff;
            padding-right: 20px;
            position: relative;
            z-index: 1;
            display: inline-block;
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            border-bottom: 2px solid #CCDDE3;
            top: 50%;
            left: 0;
        }
    }

    .field-name-field-text-sekcie{
        @extend .midc;
        @extend .midc-744;
    }

    .btn-red{
        @extend .btn-primary;
        @extend .btn-icon;
        @extend .btn-chevron;
        margin: 40px auto;
    }
    .aktuality-block{
        margin-top: 50px;
        .block-title{
            display: none;
        }
    }
  }