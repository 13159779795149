.view-22-stanoviska{
    padding: 40px 50px;
    position: relative;
    --swiper-navigation-size: 22px;
    --swiper-theme-color: $green;
    @include breakpoint(tablet){
      overflow: hidden;
    }

    .swipe-stanoviska{
      @extend .midc;
      @include breakpoint(tablet-plus){
        overflow: hidden;
        &.no-carousel{
          .swiper-button-prev,
          .swiper-button-next{
            display: none;
          }
          .swiper-wrapper{
            display: flex;
            justify-content: center;
        }
        }
      }
    }

    .swiper-wrapper{
        align-items: center;
    }
    .swiper-button-next{
      &::after{
        text-shadow: -1px 0 0 rgba(70, 113, 45, 1);
      }
      @media screen {

      }
    }
    .swiper-button-prev{
      &::after{
        text-shadow: 1px 0 0 rgba(70, 113, 45, 1);
      }
    }

    .views-field-title{
        a{
            color: $blue;
            font-style: italic;
            font-weight: 700;
            text-decoration: none;
            display: block;
            margin-bottom: 15px;
        }
    }

    .views-field-view-node{
        @extend .text-chevron-right;
    }
  }