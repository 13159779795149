#section-uspechy{
    background-color: $blue;
    color: #fff;
    text-align: center;
    padding-top: 50px;
    @extend .windmill;
    margin-top: 50px;
    @include breakpoint(tablet-plus){
        padding-top: 118px;
    }

    h2{
        color: #fff;
        margin: 0 auto 30px;
        width: 200px;
        @include breakpoint(tablet-plus){
            width: auto;
            margin: 18px 0 20px;
        }
    }
    h3{
        color: #fff;
        @include fontSize(22);
        @include lineHeight(29);
        margin: 30px 0 20px;
        @include breakpoint(tablet-plus){
            margin: 30px 0 20px;
            @include fontSize(22);
            @include lineHeight(29);
        }
    }

    .icon-container{
        margin: 40px 0;
        padding: 0 30px;
        @include breakpoint(tablet-plus){
            margin: 88px auto 50px;
            padding: 0;
            flex: 1 1 0px;
        }
    }



    .video-container{
        margin: 33px 0 55px;
        @include breakpoint(tablet-plus){
            margin: 0;
        }
        video{
            width: 100%;
            height: auto;
            border-radius: 6px;
        }

        iframe{
            width: 100%;
            height: calc((100vw - 30px)*0.554);
            border-radius: 6px;
            @include breakpoint(tablet-plus){
                    height: calc((50vw - 41px)*0.554);
                    max-height: 305px;
            }
        }
    }

    .row{
        .left{
            padding: 0 10px;
        }
        @include breakpoint(tablet-plus){
            max-width: 1140px;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            gap: 54px;
            justify-content: space-between;
            .left{
                text-align: left;
                padding: 0;
                width: 446px;
            }
            .right{
                max-width: 550px;
            }
            .left,
            .right{
                flex: 1 1 0px;
            }
            p.lead{
                @include fontSize(18);
                line-height: 150%;
            }
        }

    }
}

.windmill{
    padding-bottom: 130px;
    position: relative;
    @include breakpoint(tablet-plus){
        padding-bottom: 146px;
    }
    &::after{
        content: '';
        width: 280px;
        height: 176px;
        background: url(#{$theme}/images/blobs/windmills.svg) center center no-repeat transparent;
        position: absolute;
        bottom: -63px;
        left: -140px;
        margin-left: 50%;
        @include breakpoint(tablet-plus){
            background: url(#{$theme}/images/blobs/windmills-desktop.svg) center center no-repeat transparent;
            width: 670px;
            height: 247px;
            bottom: -101px;
            left: -335px;
        }
    }
}