.ocenenia-block{
  h2{
      @include breakpoint(tablet-plus){
        text-align: center;
        margin-bottom: 75px;
  }
  }
}
.view-ocenenia {
  border-bottom: 2px solid #CCDDE3;
  margin: 50px 0 40px;
  @include breakpoint(tablet-plus){
    margin-bottom: 167px;
  }

  .ui-widget {
    font-family: 'LidlFont', sans-serif;
  }

  .ui-accordion-header {
    border: 0;
    padding: 15px 50px 15px 0;
    background: none;
    border-top: 2px solid #CCDDE3;
    margin: 0;

    &:first-child {
      border-top: 0;
    }

    border-radius: 0;

    .ui-accordion-header-icon {
      right: 0;
      left: auto;
      background: url(#{$theme}/images/icons/chevron-down.svg) center right no-repeat transparent;
      filter: brightness(0) saturate(100%) invert(16%) sepia(45%) saturate(5640%) hue-rotate(180deg) brightness(93%) contrast(101%);
    }

    &.ui-state-active {
      .ui-accordion-header-icon {
        background: url(#{$theme}/images/icons/chevron-up.svg) center right no-repeat transparent;
      }
    }

    a {
      @extend .lead;
      font-weight: 700;
      color: $text;
      display: block;
    }
  }

  .ui-accordion-content {
    border: 0;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
    position: relative;
    color: $blue;
    overflow-y: hidden;

    p {
      margin: 0 0 10px;
    }

    .swipe-ocenenia {
      position: relative;
      margin-top: 50px;
      margin-bottom: 40px;
    }

    --swiper-navigation-size: 22px;
    --swiper-theme-color: #fff;

    .swiper-button-next {
      padding: 20px;
      border-radius: 50px;
      background: $green;

      &::after {
        text-shadow: -1px 0 0 #fff;
        margin-left: 4px;
      }
    }

    .swiper-button-prev {
      padding: 20px;
      border-radius: 50px;
      background: $green;

      &::after {
        text-shadow: 1px 0 0 #fff;
        margin-right: 4px;
      }
    }

    .views-field-field-featured-obrazok {
      img {
        display: block;
        border-radius: 12px;
      }
    }

    .views-field-title {
        min-height: 60px;
      h4 {
        @extend .lead;
        margin: 20px 0 10px;

        a {
          text-decoration: none;
          color: $blue;
          font-weight: 400;
        }
      }
    }
  }
}
