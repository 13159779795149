// Base Typography Styles
html{
  &.menu-opened{
    overflow: hidden;
  }
}
body{
  font-family: $base-font;
  font-weight: 400;
  @include fontSize(18);
  line-height: 142%;
  color: $ctext;
  margin: 0;
  width: 100%;
  &.menu-opened{
    overflow: hidden;
  }
}
.body-desktop{
  @include fontSize(18);
  line-height: 170%;
}

.body-bold{
  @include fontSize(16);
  line-height: 150%;
  font-weight: bold;
}

.lead{
  @include fontSize(19.2);
  line-height:150%;
}

// Headers
h1, h2, h3, h4, h5{
  font-weight: 700;
  line-height: 130%;
  color: $ctext_dark;
}

h1, .h1 {

  @include fontSize(32.44);
  line-height: 125%;
  letter-spacing: -0.02em;
  .prepend-heading{
    display: block;
    @include fontSize(18);
    line-height: 140%;
    text-transform: uppercase;
    font-weight: 400;
  }
}

h2, .h2 {
  @include fontSize(30);
  line-height: 120%;
  letter-spacing: -0.02em;
  margin: 30px 0;
}
.mobile-h2{
  @include breakpoint(max-tablet){
    @include fontSize(30);
    line-height: 120%;
    letter-spacing: -0.02em;
  }
}

.desktop-h2{
  @include breakpoint(tablet){
    @include fontSize(44);
    line-height:130%;
    color: $black;
  }
}

h3, .h3 {
  //font-size: 1.728rem;
  @include fontSize(22);
  @include lineHeight(28.6);
  letter-spacing: -0.02em;
  margin-bottom: 20px
}

.mobile-h3 {
  //font-size: 1.728rem;
  @include breakpoint(max-tablet-plus){
    @include fontSize(22);
    @include lineHeight(28.6);
    letter-spacing: -0.02em;
    margin-bottom: 20px
  }
}

h4, .h4 {
  //font-size: 1.44rem;
  @include fontSize(20);
}
.desktop-h4{
  @include fontSize(28.13);
  line-height: 130%;
  font-weight: 700;
}
h5, .h5, .lead {
  //font-size: 1.2rem;
  @include fontSize(19.2);
  line-height: 150%;
}

.lead-desktop{
  @include breakpoint(tablet-plus){
    @include fontSize(22.5);
    line-height: 150%;
  }
}

.small{
  //font-size: 0.833rem;
  @include fontSize(13.33);
  line-height: 135%;
}

.link{
  //font-size: 1.125rem;
  @include fontSize(18);
  line-height: 138%;
}

.lead{
    @include fontSize(20);
    @include lineHeight(28);
}

pre {
  white-space: pre-wrap;
  overflow-x: auto;
}

em,
i {
  font-style: italic;
}

blockquote {
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}

sup {
  font-size: 50%;
  line-height: 0;
  vertical-align: super;
}

sub {
  font-size: 50%;
  line-height: 0;
  vertical-align: sub;
}

.mobile-lead{
  @include fontSize(19.2);
  line-height: 150%;
  color: $gray1
}

.text{
  ul{
    padding: 0;
    margin: 1em 0;
    li{
      list-style: none;
      padding-left: 42px;
      position: relative;
      margin: 10px 0;
      &::before{
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 6px;
        background-color: $gray1;
        left: 12px;
        margin-top: 6px;
      }
    }
  }
}

.label{
  font-weight: 700;
}

.field-bygstudio-kurzz-text,
.text,
.stredisko-txt,
.field-bygstudio-faq-text,
.field-body,
.field-text-odseku,
.lead{
  a{
    color: $green;
  }
}

.check-green,
.ul-fajky{
  li{
    list-style: none;
    margin: 10px 0;
    position: relative;
    &::before{
      content: '';
      width: 24px;
      height: 24px;
      background: url(#{$theme}/images/icons/check-green.svg) center center no-repeat transparent;
      background-size: 24px 24px;
      position: absolute;
      top: 1px;
      left: -37px;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@include breakpoint(tablet-plus){
  body{
    @include fontSize(18);
    line-height: 150%;
  }

  .body-bold{
    @include fontSize(18);
    line-height: 150%;
    font-weight: bold;
  }

  h1, .h1{
    @include fontSize(55);
    line-height: 120%;
  }
  h2, .h2 {
    @include fontSize(40);
    line-height: 122%;
  }

  h3, .h3, .desktop-h3 {
    @include fontSize(35);
    line-height: 130%;
  }

  h4, .h4 {
    @include fontSize(28.13);
  }

  h5, .h5 {
    @include fontSize(18);
  }

  .lead{
    @include fontSize(22.5);
    @include lineHeight(31);
  }

  .small{
    @include fontSize(15);
    line-height: 133%;
  }

  .link{
    @include fontSize(18);
    line-height: 138%;
  }
}




