.center-row {
  text-align: center;
}

.center {
  text-align: center;
}

.mb0{
  margin-bottom: 0 !important;
}

.mb-80{
  margin-bottom: 80px !important;
}

.mt-45{
  margin-top: 45px;
}

.mt-50{
  margin-top: 50px;
}

.mb-40{
  margin-bottom: 50px;
}

.mt-96{
  margin-top: 96px;
}

.mb-60{
  margin-bottom: 60px;
}

.iblock{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

  @include breakpoint(tablet-plus){
   #center-content{
    min-height: calc(100vh - 618px);
   }
  }


.content-padding {
  padding: 0 15px;
}


.pad-0, .full-container.pad-0 {
  padding: 0;
}

.page-inner {
  margin: 0 $page-margin-lr;

  @include breakpoint(desktop) {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.page-inner-desktop {
  @include breakpoint(tablet-plus) {
    margin: 0 $page-margin-lr;
  }

  @include breakpoint(desktop) {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.page-inner-580 {
  margin: 0 $page-margin-lr;

  @include breakpoint(tablet-plus) {
    max-width: 580px;
    margin: 0 auto;
  }
}

.content-container {
  @extend .content-padding;
}

p {
  @include fontSize(18);

  @include breakpoint(tablet-plus) {
    @include fontSize(18);
    line-height: 150%;
  }
}

.content-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.block-margin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.flex-content{
  max-width: 1140px;
  margin: 0 auto;
  p{
    @extend .lead;
  }
  @include breakpoint(tablet-plus){
    display: flex;
    justify-content: space-between;
    gap: 50px;
    .page-left, .page-right{
      margin-top: 60px;
      flex: 1 1 0px;
      h1{
        margin: 0 0 30px;
      }
    }

    .page-right{
      iframe{
        aspect-ratio: 16/9;
        border-radius: 12px;
      }
    }
  }
}

.round-block {
  @extend .block-margin;
  background-color: $white;
  border: 1px solid $green;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  a.box-link {
    display: block;
    text-decoration: none;
    padding: 15px;
  }
}

.field--label-inline .field__label::after {
  content: ' ';
}

.accordion,
.field--name-field-accordeon {
  border-bottom: 1px solid $gray5;
  margin-bottom: 20px;

  &.closed {

    .field-bygstudio-cc-obsah-odseku,
    .field-bygstudio-faq-text {
      display: none;
    }
  }

  .field__label,
  .field-bygstudio-cc-nadpis-odseku,
  .views-field-item-description {
    cursor: pointer;
    margin-bottom: 10px;
    padding-right: 30px;
    position: relative;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: url(#{$theme}/images/icons/chevron-down.svg) center center no-repeat transparent;
      padding: 0 5px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -9px;
      text-align: center;
    }

    &:hover,
    &.active {

      &::after {
        background: url(#{$theme}/images/icons/chevron-down.svg) center center no-repeat transparent;
      }
    }

  }
}

.greenc {
  color: $green;
}

.redc {
  color: $red;
}

.xclose {
  width: 40px;
  height: 40px;
  background: url(#{$theme}/images/icons/close.svg) left center no-repeat transparent;
  background-size: 40px;
}

.chevron-right {
  padding-right: 20px;
  display: block;

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 3px;
    background: url(#{$theme}/images/icons/chevron-right.svg) center top 1px no-repeat transparent;
    margin-right: -20px;
  }
}

.page-view-title{
  text-align: center;
  h1{
    margin: 40px 0 20px;
  }
}

.field-name-field-main-img{
  img{
    display: block;
    border-radius: 12px;
  }
}
.field-name-field-na-stiahnutie,
.field-name-field-na-stiahnutie-2{
  margin: 50px 0 40px;
  overflow: hidden;

  .field-label{
    @extend h3;
  }
  .field-item{
    padding: 30px 20px;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 15px 0;
    background: #F5FAFC;
    @include breakpoint(tablet-plus){
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;
    }
    .file-name-text{
      margin-bottom: 20px;
      word-break: break-word;
      @include breakpoint(tablet-plus){
        @include fontSize(18);
        margin: 0;
      }
    }
    .download-links{

      a{
        display: block;
        margin: 10px 0;
        @include breakpoint(tablet-plus){
            display: inline-block;
            margin:0 0 0 20px;
        }
        img{
          display: none;
        }
      }
    }
  }
}

.field-name-field-na-stiahnutie-2{
  @include breakpoint(tablet-plus){
    margin-bottom: 80px;
  }
}

#colorbox {
  #cboxTitle {
    display: none;
  }

  #cboxContent {
    background: none;
  }

  #cboxLoadedContent {
    border-radius: 20px;
    background: none;
    padding: 7px;
    border: none;

    img {
      border-radius: 20px;
    }
  }
}

#cboxControls {

  #cboxPrevious {
    background: rgba(255, 255, 255, .8);
    border-radius: 0 32px 32px 0;
    width: 32px;
    left: 0;

    &:hover {
      background: $white;
    }

    &::before {
      content: '';
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-left: 3px;
      background: url(#{$theme}/images/icons/chevron-right.svg) center top no-repeat transparent;
      background-size: 32px 32px;
      transform: rotate(180deg);
      position: absolute;
      left: -5px;
      top: 50%;
      margin-top: -15px;
    }
  }

  #cboxNext {
    background: rgba(255, 255, 255, .8);
    border-radius: 32px 0 0 32px;
    right: 0;

    &:hover {
      background: $white;
    }

    &::before {
      content: '';
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-left: 3px;
      background: url(#{$theme}/images/icons/chevron-right.svg) center top no-repeat transparent;
      background-size: 32px 32px;
      position: absolute;
      right: -5px;
      top: 50%;
      margin-top: -15px;
    }
  }

  #cboxPrevious,
  #cboxNext,
  #cboxSlideshow,
  #cboxClose {
    z-index: 99999999999;
    position: fixed;
  }

  #cboxClose {
    display: none;
  }
}

