.hamburger {
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-left: 45px;
  div {
    height: 3px;
    background-color: transparent;
    border-radius: 0;
    top: calc(50% - 2px);
    width: 25px;
    cursor: pointer;
    position: absolute;
    left: 0;

    &::before {
      top: -4px;
    }

    &::after {
      bottom: -6px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      box-sizing: border-box;
      background-color: $blue;
      height: inherit;
      width: inherit;
      border-radius: inherit;
    }

  }

  &.opened div {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    background-color: transparent;

    &::before {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0px;
    }

    &::after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      bottom: 0px;
    }
  }

  @include breakpoint(desktop) { display: none;}
}
