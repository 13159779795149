// Mixins

// Px to Em mixin
// Unit mixins
// Ems for breakpoints, using browser context font size
@use "sass:math";

@function bp-em($pixels, $context: 16) {
  @return #{math.div($pixels, $context)}em;
}

// Ems in relation to base font size
@function em($pixels, $context: $base-font-size) {
  @return #{math.div($pixels, $context)}em;
}

// Rem conversion
// Convert pixel values to rem.
@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

// Rem font size conversion w/ px fallbacks
// https://github.com/stubbornella/oocss/blob/master/oocss/src/components/utils/_fontSize.scss
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 *
 */

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: math.div($size, $base-font-size);
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: #{$size}px;
  font-size: calculateRem($size);


}@mixin lineHeight($size) {
  line-height: #{$size}px;
  line-height: calculateRem($size);
}

//Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Preferred version of clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Centers a block level element, requires width
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Vertical align, all browsers http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin gradient($direction, $list) { 
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);  
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args; 
  transition: $args;
}

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == mini-mobile {
    @media screen and (max-width: $mini-mobile) { @content; }
  }
  @else if $point == mobile {
    @media screen and (max-width: $mobile) { @content; }
  }
  @else if $point == nodesktop {
    @media screen and (max-width: $nodesktop) { @content; }
  }
  @else if $point == tablet {
    @media screen and (min-width: $tablet) { @content; }
  }
  @else if $point == max-tabletus {
    @media screen and (max-width: $max-tablet) { @content; }
  }
  @else if $point == tablet-plus {
    @media  screen and(min-width:$tablet-plus)  { @content; }
  }
  @else if $point == max-tablet-plus {
    @media screen and (max-width: $max-tablet-plus) { @content; }
  }
  @else if $point == desktop {
    @media screen and (min-width: $desktop) { @content; } 
  }
  @else if $point == desktop-plus {
    @media screen and (min-width: $desktop-plus) { @content; }
  }
  @else if $point == print {
    @media ($print)  { @content; }
  }
}

@mixin highlight {
  background: $highlight;
  border: 1px solid $highlight-border;
  box-sizing: border-box;
  border-radius: 4px;
}

@mixin tag {
  background: $highlight;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2px;
  padding: 3px 4px 4px;
  margin: 0 5px 0 0;
}