.view-22-csr-oblasti{
    @include breakpoint(tablet){
        margin-bottom: 50px;
    }
    .views-row{
        position: relative;
        margin-bottom: 15px;
    }

    @include breakpoint(tablet){
        .view-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 940px;
            margin: 0 auto;
            .views-row{
                width: calc(50% - 10px);
                margin-bottom: 20px;
                @include breakpoint(tablet-plus){
                    width: calc(50% - 25px);
                    margin-bottom: 50px;
                }
            }
        }
    }



    .views-field-field-oblast-text,
    .views-field-view-node{
        display: none;
        @include breakpoint(tablet-plus){
            display: block;
        }
        a{
            @extend .btn;
            @extend .btn-secondary;
            @extend .btn-icon;
            @extend .btn-rounded;
            @extend .btn-chevron-secondary;
            margin: 33px 0 0px;
            @include breakpoint(tablet-plus){
                margin: 20px 0 15px;
            }
        }
    }

    .views-field-field-oblast-img{
        @include breakpoint(max-tablet-plus){
            &::after{
                background: linear-gradient(0deg, #181B29 0%, rgba(18, 21, 34, 0) 100%);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 50%;
                content: '';
                border-radius: 12px;
                pointer-events: none;
            }
        }
        img{
            display: block;
            border-radius: 12px;
        }
    }

    .views-field-title{
        @include breakpoint(max-tablet-plus){
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 120px;
            pointer-events: none;
            h3{
                margin: 0;
            }
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        @include breakpoint(tablet-plus){
            h3{
                margin: 40px 0 20px;
            }
            a{
                text-decoration: none;
                color: $text;
            }
        }

    }
}