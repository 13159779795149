.chosen-container{
  width: 100% !important;
  margin-top: 5px;

  &.chosen-with-drop{
    .chosen-single{
      border: 2px solid #CCDDE3;
      border-bottom: none;
      border-radius: 25px 25px 0 0;
      background: #fff;
    }
  }
  .chosen-single{
    background: #fff;
    height: 50px;
    border-radius: 25px;
    padding: 12px 20px;
    @include fontSize(18);
    margin-top: 5px;
    box-shadow: none;
    border: 2px solid #CCDDE3;

    div{
      width: 30px;
      b{
        background: url(#{$theme}/images/icons/chevron-down.svg) left center no-repeat transparent;
      }
    }
  }

  .chosen-drop{
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    border: 2px solid #CCDDE3;
    border-top: none;
  }
  .chosen-results{
    margin: 0;
    padding: 0;

    li{
      padding: 8px 20px;
      &.highlighted{
        background: $green;
      }

    }
  }
}
select{
  background: #fff;
  height: 50px;
  border-radius: 25px;
  padding: 12px 20px;
  @include fontSize(18);
  border: 2px solid #CCDDE3;
  color: $text;
  width: 100%;
  margin-top: 5px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: url(#{$theme}/images/icons/chevron-down.svg) right 20px center no-repeat transparent;
  font-family: 'LidlFont', sans-serif;

}
input{
  font-family: 'LidlFont', sans-serif !important;
}

#edit-submit-search{
  cursor: pointer;
}

.views-exposed-form,
.webform-client-form,
#user-login{

  label{
    color: $text;
  }
  .views-exposed-widget{
    width: 100%;
    padding: 0;
  }

  .form-type-checkbox,
  .form-type-bef-checkbox {

    /* Base for label styling */
    margin: 10px 0;
    position: relative;
    display: block;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }

    [type="checkbox"]:not(:checked)+label,
    [type="checkbox"]:checked+label {
      position: relative;
      padding: 2px 0 0 35px;
      cursor: pointer;
      font-size: 15px;
      line-height: 25px;
      font-weight: normal;
      display: inline-block;
      img{
        display: inline-block;
        margin-top: 4px;
        float: left;
      }
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked)+label:before,
    [type="checkbox"]:checked+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 1px solid $gray4;
      background: #FFF;
      border-radius: 5px;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked)+label:after,
    [type="checkbox"]:checked+label:after {
      content: '';
      background: url(#{$theme}/images/icons/check-white.svg) left center no-repeat transparent;
      width: 20px;
      height: 20px;
      border-radius: 7px;
      position: absolute;
      top: 4px;
      left: 4px;
      color: #FFF;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked)+label:after {
      opacity: 0;
    }

    [type="checkbox"]:checked+label:after {
      opacity: 1;
    }

    [type="checkbox"]:checked+label:before {
      background: $green;
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }




  .form-type-radio {
    /* Base for label styling */
    margin: 10px 0;
    position: relative;
    display: block;

    [type="radio"]:not(:checked),
    [type="radio"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }

    [type="radio"]:not(:checked)+label,
    [type="radio"]:checked+label {
      position: relative;
      padding: 2px 0 0 35px;
      cursor: pointer;
      font-size: 15px;
      line-height: 25px;
      font-weight: normal;
      display: inline-block;
      .description{
        @extend .small;
        ul{
          padding: 0 0 0 12px;
          margin: 0;
          color: $green;
          li{
            @include fontSize(14);
            line-height: 120%;
          }
        }
      }
      .small-description{
        @include fontSize(14);
        line-height: 120%;
        color: $gray3;
        display: none;
      }
    }
    [type="radio"]:checked+label {
      .small-description{
        display: block;
      }
    }

    /* checkbox aspect */
    [type="radio"]:not(:checked)+label:before,
    [type="radio"]:checked+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 1px solid $gray4;
      background: #FFF;
      border-radius: 13px;
    }

    /* checked mark aspect */
    [type="radio"]:not(:checked)+label:after,
    [type="radio"]:checked+label:after {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 7px;
      position: absolute;
      top: 7px;
      left: 7px;
      background: #FFF;
    }

    /* checked mark aspect changes */
    [type="radio"]:not(:checked)+label:after {
      opacity: 0;
    }

    [type="radio"]:checked+label:after {
      opacity: 1;
    }

    [type="radio"]:checked+label:before {
      background: $green;
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }
}

.confirm-parent, .password-parent,
div.form-item div.password-suggestions{
 max-width: 100%;
}
div.password-confirm{
  width: 100%;
  margin: 0;
  padding: 3px;
  background: $gray5;
}

#user-login{
  text-align: center;
  max-width: 440px;
  margin: 40px auto;
  .description{
    padding: 4px;
    color: $gray3;
  }
}

.webform-client-form,
.entity-entityform-type,
#user-login{

  .grippie{
    display: none
  }

  .form-item {
    margin: 20px 0;

    label:not(.option){
      @include fontSize(15);
      text-transform: uppercase;
      line-height: 20px;
      color: $gray3;
      font-weight: bold;
      display: block;
    }
    .description{
      @extend .small;
    }

    label.option + .description{
      font-size: 1em;
      padding-left: 35px;
      margin-top: -2px;
    }


  }

  .field-widget-date-popup {
    fieldset {
      padding: 0;
      border: none;

      legend {
        display: none;
      }
    }

    .container-inline-date {
        width: 100%;
      .form-item {
        margin-right: 0;
        width: 100%;

        .date-padding {
          padding: 0;

          .description {
            display: none;
          }
        }

        input {
          margin: 5px 0 0;
        }
      }
    }
  }

  .form-item{
      &.error{
          input{
            border: 1px solid $red;
          }
        .small{
            color: $red;
        }
    }
  }
  input,
  textarea{
    padding: 10px;
    box-sizing: border-box;
    height: 50px;
    line-height: 30px;
    border-radius: 10px;
    border: 1px solid $gray4;
    margin: 5px 0 0;
    width: 100%;
    font-family: 'LidlFont', sans-serif;

    &.form-submit{
        @extend .button;
        @extend .btn-green;
        @extend .btn-medium;
        border: 0;
        cursor: pointer;
    }
    &.webform-calendar{
      width: auto;
      border: 0;
      padding:0;
      height: 25px;
    }
  }

  a#edit-cancel{
    @extend .button;
    @extend .btn-white;
    @extend .btn-medium;
    max-width: 120px;
  }

  .webform-component-textarea{
    .form-textarea-wrapper{
      display: block !important;
    }
  }

  textarea{
    height: 150px;
    overflow: hidden;
    width: 100%;
  }
  select{
    padding: 10px;
    box-sizing: border-box;
    height: 50px;
    line-height: 30px;
    border-radius: 10px;
    border: 1px solid $gray4;
    margin: 5px 0 0;
    width: 100%;
    background: $white;
  }

  .webform-component-file{
    background: $gray6;
    padding: 20px 15px;
    border-radius: 15px;
    input{
      background: $white;
      &.form-submit{
        margin: 20px 0;
        @extend .btn-green;
        cursor: pointer;
      }
    }
    .description{
      @extend .small;
    }
  }

  .webform-submit{
    min-width: 250px;
  }
  .chosen-container{
    width: 100% !important;
    .chosen-single{
      padding: 10px;
      box-sizing: border-box;
      height: 50px;
      line-height: 30px;
      border-radius: 10px;
      border: 1px solid $gray4;
      margin: 5px 0 0;
      width: 100%;
      background: $white;
    }

    .chosen-drop{
      margin-top: -10px;
        ul{
          li{
            line-height: 20px;
            padding: 3px 5px;
            @include fontSize(14);
          }
        }

    }

  }
}

.webform-client-form{
  .webform-component-markup{
      text-align: center;
      p{
          margin-bottom: 40px;
      }
  }

  .webform-component{
      margin: 16px 0;
      label:not(.option){
          text-transform: none;
          color: $text;
          @include fontSize(18);
          margin-bottom: 6px;
      }

      input,
      textarea{
        border-radius: 25px;
        border-width: 2px;
        @include fontSize(18);
        padding: 0 20px;
      }
      textarea{
        border-radius: 12px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
  }

  .form-actions{
    text-align: center;
    input{
      @extend .btn-icon;
      @extend .btn-chevron;
      width: 222px;
      border-radius: 25px;
      margin: 0px auto;
      margin-bottom: 50px;
    }
  }
}