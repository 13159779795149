#section-ocenenia{
    text-align: center;
    @include breakpoint(tablet-plus){
        padding: 65px 0  0;
    }
    .flexcol{
        display: flex;
        flex-flow: column nowrap;
        @include breakpoint(tablet-plus){
            flex-flow: row;
            gap: 116px;
            justify-content: space-between;
            max-width: 1140px;
            margin:  0 auto;
        }
        align-items: center;
        text-align: center;
        .left{
            order: 2;
            padding: 0 15px;
            h2{
                margin: 20px 0;
                @include breakpoint(tablet-plus){
                    margin-top: 43px;
                }
            }
        }
        .right{
            order: 1;
            width: 100%;
            margin: 30px 0 20px;
        }
        @include breakpoint(tablet-plus){
            .left{
                order: 1;
                padding: 0;
                text-align: left;
                max-width: 545px;
                flex: 1 1 0px;
            }
            .right{
                order: 2;
                width: auto;
                margin: 0;
                flex: 1 1 0px;
            }
            p.lead{
                @include fontSize(18);
                line-height: 150%;
            }
        }
    }

    .logo-group{
        position: relative;
        width: 100%;
        height: 78vw;
        @include breakpoint(tablet-plus){
            height: 323px;
            max-width: 473px;
        }
        img{
            position: absolute;
            max-width: none;
        }
        .logo-1{
            width: calc(0.457*(100vw - 30px));
            height: calc(0.445*(100vw - 30px));
            top: 0;
            right: 0;
        }
        .logo-2{
            width: calc(0.475*(100vw - 30px));
            height: calc(0.475*(100vw - 30px));
            top: 5.5vw;
            left: 0;
        }
        .logo-3{
            width: calc(0.46*(100vw - 30px));
            height: calc(0.46*(100vw - 30px));
            top: 40vw;
            left: calc(0.31*(100vw - 30px));;
        }
        @include breakpoint(tablet-plus){
            .logo-1{
                width: calc(0.457*(100% + 15px));
                height: auto;
                top: 0;
                right: 0;
            }
            .logo-2{
                width: calc(0.475*(100% + 15px));
                height: auto;
                top: 11%;
                left: 0;
            }
            .logo-3{
                width: calc(0.46*(100% + 15px));
                height: auto;
                top: 65%;
                left: calc(0.31*(100% + 15px));
            }
        }
    }
    .row{
        @include breakpoint(tablet-plus){
            max-width: 1140px;
            margin: 0 auto;
            text-align: left;
            a.btn{
                margin: 0 0 110px;
            }
        }
    }
    .btn{
        margin: 20px auto 60px;
        @extend .btn-icon;
        &::after{
            display: none;
        }
        @include breakpoint(tablet-plus){
            margin: 90px auto 75px;
            &::after{
                display: block;
                background-position: right center;
            }
        }
    }
}