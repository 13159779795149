.kampan-bublina {
  max-width: 485px;
  position: relative;
  text-align: center;
  background: url(#{$theme}/images/blobs/kampan-bublina.svg) center center no-repeat transparent;
  background-size: 100% 100%;
  padding: 40px 60px 50px 30px;
  font-size: 20px;
  line-height: 28px;
  color: $white;
  font-weight: 700;
  margin-top: 1.5em;
  margin-bottom: -35px;
  z-index: 10;


    @include breakpoint(tablet-plus){
        padding: 53px 77px 77px;
        font-size: 28px;
        line-height: 34px;
        margin-top: 2em;

        left: 50%;
        margin-left: -450px;
        margin-bottom: -110px;
    }

    @include breakpoint(desktop){
        margin-left: -550px;
    }
}

.kampan-hero {
  position: relative;
  margin: 0px -15px 0;
  margin-bottom: 40px;
  text-align: center;
  img{
    width: 100%;
    max-width: 2560px;
  }
  @include breakpoint(tablet-plus){
    margin-bottom: 60px;
  }
}


h1{
    .field-name-field-kampan-nadpis{
        color: #000;
        margin: 1em 0;
        @include breakpoint(tablet-plus){
          @include fontSize(62);
          @include lineHeight(68);
        }
      }
}
.kampan {
    margin-bottom: 80px;

  .field-name-field-headline-sekcie {
      text-align: center;
    h2 {
      display: block;
      position: relative;
      float: none;
      z-index: 1;
      @extend .h4;

      margin-top: 1em;
      margin-left:auto;
      display: inline-block;
      padding: 15px 25px;

      @include breakpoint(tablet-plus){
        margin-left: 60px auto 30px;
        padding: 30px 45px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(#{$theme}/images/blobs/kamapan-heading.svg) center center no-repeat transparent;
        background-size: 100% 100%;
        z-index: -1;
        filter: brightness(0) saturate(100%) invert(35%) sepia(8%) saturate(3564%) hue-rotate(54deg) brightness(101%) contrast(81%);
      }
    }
  }

  .field-name-field-text-sekcie {
    width: 100%;
    clear: both;
  }
  img.full{

    @include breakpoint(tablet-plus){
        max-width: none;
        position: relative;
        left: 50%;

        width: 920px !important;
        margin-left: -460px;
    }

    @include breakpoint(desktop){
        width: 1140px !important;
        margin-left: -570px;
    }

  }

  p{
    em{
      color: #4879A2;
    }
  }
}

.ochrana-klimy {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #bddde9;

        &::before {
          //#005371
          filter: brightness(0) saturate(100%) invert(21%) sepia(40%) saturate(3046%) hue-rotate(171deg) brightness(94%) contrast(101%);
        }
      }
    }
  }
}

.zachovanie-biodiverzity {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #46721d;

        &::before {
          //#b4be5d
          filter: brightness(0) saturate(100%) invert(61%) sepia(3%) saturate(5295%) hue-rotate(26deg) brightness(114%) contrast(105%);
        }
      }
    }
  }
}

.setrenie-zdrojov {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #402a1c;

        &::before {
          //#918d82
          filter: brightness(0) saturate(100%) invert(61%) sepia(7%) saturate(409%) hue-rotate(6deg) brightness(91%) contrast(88%);
        }
      }
    }
  }
}

.ferove-jednanie {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #462F1E;

        &::before {
          //#d5ba55
          filter: brightness(0) saturate(100%) invert(78%) sepia(41%) saturate(538%) hue-rotate(4deg) brightness(89%) contrast(93%);
        }
      }
    }
  }
}


.podpora-zdravia {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #005371;

        &::before {
          //#bddde9
          filter: brightness(0) saturate(100%) invert(89%) sepia(3%) saturate(3030%) hue-rotate(174deg) brightness(104%) contrast(83%);
        }
      }
    }
  }
}

.vedenie-dialogu {
  .kampan {
    .field-name-field-headline-sekcie {

      h2 {
        color: #b4be5d;

        &::before {
          //#46712d
          filter: brightness(0) saturate(100%) invert(39%) sepia(8%) saturate(3546%) hue-rotate(54deg) brightness(90%) contrast(78%);
        }
      }
    }
  }
}
