#main-header {

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  width: 100%;
  box-sizing: border-box;
  padding: 11px;

  @include breakpoint(tablet-plus) {
    height: 134px;
    padding: 0 20px;
    max-width: 1180px;
    margin: 0 auto;
  }

  #logo {
    width: 54px;

    @include breakpoint(tablet-plus) {
      width: 101px;
    }
  }

  #logo-lidl {
    width: 45px;
    margin-right: 4px;

    @include breakpoint(tablet-plus) {
      width: 70px;
      margin: 0;
    }
  }

  #logo-menu {
    width: 79px;
    display: block;
    float: left;
    margin: 7px 13px;
  }

  .menu-header {
    height: 93px;
    background: #fff;
    z-index: 99;
    position: relative;

    @include breakpoint(tablet-plus) {
      display: none;
    }
  }

  .menu-social {
    float: right;
    margin: 18px 20px;

    a {
      display: inline-block;
      margin: 0 0 0 10px;
    }
  }

  #navigation {
    padding: 0 15px;

    ul {
      padding: 0;

      @include breakpoint(tablet-plus) {
        margin: 0;
      }
    }

    li {
      list-style: none;

      @include breakpoint(max-tablet-plus) {
        a.depth-1 {
          color: $text;
          @include fontSize(22);
          line-height: 100%;
          font-weight: 700;
          padding: 10px 0;
          text-decoration: none;
          display: block;

          &.menuparent {
            &::after {
              content: '';
              width: 16px;
              height: 14px;
              background: url(#{$theme}/images/icons/arrow-right.svg) center center no-repeat transparent;
              display: inline-block;
              padding: 0 0 0 22px;
            }
          }
        }
      }

      a.btn-close-search{
        max-width: 222px;
        line-height: 157% !important;
      }

      @include breakpoint(tablet-plus) {
        list-style: none;
        float: left;

        a.depth-1 {
          color: $text;
          @include fontSize(14);
          @include lineHeight(22);
          font-weight: 700;
          padding: 43px 8px;

          @include breakpoint(desktop) {
            @include fontSize(16);
            padding: 43px 16px;
          }

          margin: 0px;
          text-decoration: none;
          display: block;
          z-index: 101;

          &.menuparent {
            position: relative;

            @include breakpoint(desktop) {
              padding-right: 12px;
            }


            &::after {
              content: '';
              width: 20px;
              height: 20px;
              background: url(#{$theme}/images/icons/chevron-down-menu.svg) center center no-repeat transparent;
              padding: 0 0 0 5px;

              @include breakpoint(desktop) {
                padding-left: 8px;
              }

              float: right;
            }

            &.hover {
              &::before {
                content: '';
                position: absolute;
                bottom: -6px;
                left: 50%;
                margin-left: -30px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 30px 30px 30px;
                border-color: transparent transparent #ffffff transparent;
              }
            }

          }
        }

        #search-menu-link {
          img {
            margin-top: 3px;
          }
        }

        #search-content {
          position: fixed;
          flex-direction: column;
          justify-content: flex-start;
          padding: 60px 30px;
          box-sizing: border-box;
          gap: 30px;
          top: 5vh;
          min-height: min(90vh, 800px);
          max-height: 90vh;

          h1 {
            margin: 0;
          }

          .term-list {
            margin: 0 auto;
            max-width: 544px;
          }

          form {
            width: 544px;
            margin: 0 auto 20px;
            position: relative;
          }
        }
      }




      .menu-more {
        position: absolute;
        width: 100%;
        padding: 15px;
        left: 0;
        top: 0;
        bottom: 0;

        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 200%;

        &#search-content{
          bottom: 65px;
        }

        &.show {
          margin-left: 100%;
        }

        @include breakpoint(tablet-plus) {
          display: none;
          bottom: auto;
          width: 1140px;
          max-width: 96%;
          height: 420px;
          background: $white;
          z-index: 101;
          top: 120px;
          padding: 48px 50px 0;
          border-radius: 12px;
          left: 2%;
          justify-content: space-evenly;
          gap: 50px;

          &.show {
            margin-left: 0%;
            display: flex;
          }

          .menu-col {
            width: calc((96% - 80px)/3);

            &.span-2 {
              width: calc((96% - 80px)/1.5);
              display: flex;
              justify-content: space-between;
              gap: 43px;
              flex: 1 1 0px;

              .btn-secondary{
                margin-top: 15px;
              }

              img {
                width: 100%;
                height: auto;
                align-self: flex-start;
                max-width: 329px;
              }
            }
          }
        }

        @include breakpoint(desktop) {
          gap: 100px;
          left: 50%;

          &.show {
            margin-left: -570px;
          }
        }

        .uptext {
          @include fontSize(20);
          line-height: 105%;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 14px;

          @include breakpoint(tablet-plus) {
            @include fontSize(18);
            font-weight: 400;
            margin: 0 0 22px;
          }
        }

        a {
          color: #30495F;
          line-height: 1.5;
          display: block;
          margin-bottom: 7px;
          text-decoration: none;

          @include breakpoint(tablet-plus) {
            font-weight: 700;

            &::after {
              display: none;

              @include breakpoint(desktop) {
                display: block;
              }
            }

          }

          &.btn-green {
            color: #fff;
          }
          &.btn-secondary {
            color: $green3;
          }

          @include breakpoint(max-tablet-plus) {
            &.menu-box {
              background: $green;
              border-radius: 12px;
              display: flex !important;
              justify-content: space-between;
              align-items: center;
              padding: 15px;

              h5 {
                width: calc(50% - 5px);
                @include fontSize(15);
                line-height: 140%;
                text-transform: uppercase;
                color: #30495F;
                margin: 0;
                align-self: center;
              }

              img {
                border-radius: 6px;
                display: block;
                width: calc(50% - 5px);
                align-self: center;
              }
              &.ratio-65-35{
                h5 {
                  width: calc(65% - 5px);
                }

                img {
                  width: calc(35% - 5px);
                }

              }
            }
          }

          @include breakpoint(tablet-plus) {
            &.menu-box {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;

              h5 {
                order: 2;
                font-weight: 400;
                @include fontSize(22.5);
                line-height: 150%;
                margin: 10px 0 0;
              }

              img {
                order: 1;
              }
            }
          }
        }

        .more-link {
          a {
            @extend .btn;
            @extend .btn-secondary;
            @extend .btn-icon;
            @extend .btn-rounded;
            @extend .btn-icon-grid;
            margin: 20px 0 40px;

            @include breakpoint(tablet-plus) {
              padding: 0;
              border: 0;

              &::after {
                display: block !important;
              }

              &:hover {
                background: transparent;
              }
            }
          }
        }

        @include breakpoint(max-tablet-plus) {

          .menu-block {
            padding: 30px 0 15px;
            border: solid #d0d6da;
            border-width: 2px 0;

            &.no-border-top {
              padding-top: 0;
              border-top: 0;
              margin-top: 30px;
            }

            &.no-border{
              padding-top: 0;
              border: 0;
              margin-top: 30px;
            }

            a {
              margin-bottom: 15px;
            }
          }
        }

        .ocenenia-menu {
          display: flex;
          justify-content: space-between;
          padding: 30px 0 20px;
          border-top: 2px solid #BDDDE9;
          clear: both;
          margin-top: 30px;

          @include breakpoint(tablet-plus) {
            margin-top: 20px;
          }

          img {
            width: 100px;
            height: 100px;

            @include breakpoint(tablet-plus) {
              width: 30%;
              height: auto;
            }
          }

          &+a {
            @extend .btn;
            @extend .btn-secondary;
            @extend .btn-icon;
            @extend .btn-rounded;
            @extend .btn-icon-grid;
            margin: 20px 0 40px;

            @include breakpoint(tablet-plus) {
              padding: 0;
              border: 0;

              &::after {
                display: block !important;
              }

              &:hover {
                background: transparent;
              }
            }
          }
        }

        @include breakpoint(max-tablet-plus) {
          @include transition(all .4s ease);

          .back {
            padding: 17px 0;
            cursor: pointer;

            &::before {
              content: '';
              width: 30px;
              height: 30px;
              background: url(#{$theme}/images/icons/arrow-left.svg) left center no-repeat transparent;
              float: left;
              padding: 0 15px 0 0;
            }

          }
        }

        @include breakpoint(tablet-plus) {
          .back {
            display: none
          }
        }
      }


    }

    @include breakpoint(tablet-plus) {
      ul.noboldul {
        li {
          a {
            @include fontSize(22.5);
            font-weight: 400;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  #responsive-nav {
    @include transition(all .4s ease);

    @include breakpoint(max-tablet-plus) {
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 100vh;
      height: 100vh;
      width: 100vw;
      z-index: 999;
      background-color: #fff;

      .menu-content {
        position: fixed;
        bottom: 70px;
        top: 67px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100vh;
        height: calc(100vh - 137px);

        @include transition(all .4s ease);


        .navigation {
          width: 100%;
        }

        &.secondary {
          margin-left: -100%;
        }
      }
    }


    &.opened {
      margin-top: 0;

      @include breakpoint(max-tablet-plus) {
        .menu-content {
          margin-top: 0;
        }
      }
    }
  }

  .main-menu-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    height: 60px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.15);

    .search-mobile {
      width: 60px;
      height: 60px;
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 34px;
        background-color: #cddce1;
        left: 0;
        top: 50%;
        margin-top: -17px;
      }
    }

    .close-menu {
      width: calc(100% - 60px);
      height: 60px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

  }

  @include breakpoint(nodesktop) {}

  @include breakpoint(tablet-plus) {}

  @include breakpoint(desktop) {}

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  @include opacity(0);
  @include transition(all 0.3s ease);
  pointer-events: none;

  &.show {
    @include opacity(1);
  }
}

.menu-opened{
  .overlay {
    @include breakpoint(tablet-plus) {
      pointer-events: all;
    }
  }
}
