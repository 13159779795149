
  #block-block-1{
    text-align: center;
    @extend .clearfix;


    p{
      @extend .lead-desktop;
    }
    @include breakpoint(tablet-plus) {
      text-align: left;
      width: 100%;
      max-width: 940px;
      padding-right: 200px;
      box-sizing: content-box;
      margin: 0 auto 66px;
    }
    h3{
      @extend .h2;
      @include breakpoint(tablet-plus) {
        float: left;
        width: 250px;
        text-align: left;
        margin: 0 50px 0 0;
      }
    }
  }

@include breakpoint(tablet-plus) {

  .front, #aktuality{
    .view-22-aktuality .view-content{
      gap: 50px;
      margin-bottom: 50px;
      .views-row{
        margin: 0 0 40px;
      }
    }
  }
}

.node-frontpage {


  @include breakpoint(tablet) {}

  @include breakpoint(tablet-plus) {}

  @include breakpoint(desktop) {}

  @include breakpoint(desktop-plus) {}

}

