// Small administrative fixes

// Adding underline under admin menu items
ul.tabs {
  border-bottom: 1px solid #ccc;
}

// Fixing contextual link dropdowns if overriden in theme
ul.contextual-links li {
  display: block !important;
  float: none !important;
  position: relative !important;
}

.contextual-links a {
  color: #000 !important;

  &:hover {
    color: #000 !important;
    text-decoration: underline !important;
  }
}

// Removing dropdown arrow from expanded menu items
.menu-item--expanded,
.menu-item--collapsed {
  list-style-type: none;
  list-style-image: none;
}

// Adding visible styling to unpublished nodes
.node--unpublished {
  background-color: #fff4f4;
}


/* ------------------- ADMIN -------------- */
body.admin-menu{
	margin-top: 29px !important;
}


body.user-role-administrator{
	&.admin-menu.adminimal-menu{
		margin-top: 29px !important;
	}
}

.ico-home{
	background:url(login/home.png) left center no-repeat;
	width:16px;
	height:16px;
	display: block;
	padding: 2px 0 2px 0;
}


.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}
.clearfix {
    display: inline-block;
}
.clearfix {
    display: block;
}

.copy-link-admin{
	margin: 20px 0;
	input{
		width: calc(100% - 150px);
		border-radius: 3px;
		padding: 5px 10px;
		box-sizing: border-box;
		border: 1px solid $green;
		line-height: 200%;
		background: $gray6;
	}

	button{
		@extend .button;
		@extend .btn-small;

		width: 130px;
		margin: 0 0 0 20px;
		display: inline-block;
		border: 0;
	}
}


#block-superfish-3 {
	background: #1b2327;
	  border-bottom:1px solid #111111;
	  border-top:1px solid #3f729b;
	  left:0;
	  text-align:left;
	  top:0;
	  width:100%;
	  height:28px;
	  margin-bottom:0;
	  z-index:9999;
	position:fixed;
  
	  font-size: 13px;
	  text-transform: capitalize;
	  ul{
		  li{
			  a{
				font-size: 13px;
			  }
		  }
	  }
  }
  
  .user-role-administrator #block-superfish-3 {
	top: 28px;
  }
  .user-role-webmaster #hamburger{
	margin-top: 28px !important;
  }
  
  #block-superfish-3 ul li#menu-661-3,
  #block-superfish-3 ul li#menu-660-3{
	  float: right;
	  margin-left: 3px !important;
  }
  #block-superfish-3 ul li#menu-661-3 a{
	  background: #E64D3B;
  }#block-superfish-3 ul li#menu-661-3 a:hover{
	  background: #c82714;
  }
  #block-superfish-3 ul li#menu-660-3{
	  background: #3f729b;
  }
  
  #block-superfish-3.moveHeader{
	  position:fixed;
  }
  #admin-menu{
	  z-index:999999;
  }
  
  #block-superfish-3 ul{
	  position:static;
	  line-height:1.4em;
	  list-style:none;
	  margin:0;
	  padding:0;
	  z-index:999;
  }
  #block-superfish-3 ul li{
	  background-image:none;
	  float:left;
	  height:100%;
	  list-style-image:none;
	  list-style-type:none;
	  margin:0 !important;
	  padding:0;
  }
  #block-superfish-3 ul li a,
  #block-superfish-3 ul li span{
	  line-height:20px;
	  display:block;
	  padding:4px 8px;
	  background:transparent none;
	  border:none;
	  color:#EEE;
	  font-weight:normal;
	  text-align:left;
	  text-decoration:none;
	  cursor:pointer;
  }
  #block-superfish-3 ul li span{
	cursor: default;
  }
  #block-superfish-3 ul li ul{
	  display:none;
	  position:absolute;
	  top:28px;
	  left:0;
	  background-color:#222;
  }
  #block-superfish-3 ul li ul ul{
	  top:0;
  }
  #block-superfish-3 ul li ul li{
  border-bottom:1px solid #333;
  }
  #block-superfish-3 ul li a:hover{
	  background-color:#00a0e3;
	  color:#fff;
  }
  
  #admin-action{
	  background:#444;
	  position:fixed;
	  left:0;
	  bottom:0;
	  border-radius:0 8px 0 0;
	  border-top:1px solid #ddd;
	  box-shadow:0 0 7px rgba(0,0,0,0.5);
	  z-index:999999;
  }
  
  #admin-action ul{
	  margin:0;
	  padding:0;
	  list-style:none;
  }
  #admin-action ul a{
	  color:#fff;
	  padding:5px 20px 3px 10px;
	  display:block;
  
  }
  #admin-action ul a:hover{
	  color:#acdf12;
	  background:#000;
  }
  
  
  .ico-home{
	  background:url(login/home.png) left center no-repeat;
	  width:16px;
	  height:16px;
	  display: block;
	  padding: 2px 0 2px 0;
  }
  
  #ui-datepicker-div, .ui-datepicker{
	  width:auto
  }
  
.fa{
	  font-family: FontAwesome, sans-serif;
		font-size: 13px;
		text-transform: capitalize;
  }
  