.view-22-banner-home {
  padding-bottom: 0;
  position: relative;
  @include breakpoint(tablet-plus) {
    padding-bottom: 70px;
  }



  .view-22-banner-home{
    padding-bottom: 0;
    display: none;
    position: relative;
    margin-top: -82px;
    justify-content: center;
    align-items: center;
    z-index: 50;
    width: 100%;
    @include breakpoint(tablet-plus) {
      display: flex;
    }

    .swipe-home-thumbs{
      width: 942px;
      height: 130px;
      margin:  0 auto;
      background: url(#{$theme}/images/blobs/banner-thumbs.svg) center center no-repeat transparent;
      .swiper-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
        .swiper-slide{
          padding: 0 40px;
          border-left: 2px solid rgba(255,255,255,.4);
          height: auto;
          min-height: 54px;
          cursor: pointer;
          &.swiper-slide-thumb-active{
            font-weight: 700;
          }
          color: #fff;
          &:first-child{
            border-left: 0;
          }
        }
      }
    }
  }

  .swipe-home{
    @include breakpoint(tablet-plus){
      max-height: 690px;
      max-width: 1920px;
      margin: 0 auto;
      overflow: hidden;
    }
    @include breakpoint(max-tablet-plus){
      .swiper-slide, .hide-desktop-default{
        display: flex;
        flex-direction: column;
        .banner-headline{
          order: 2;
        }
      }
    }
  }
  .views-row {
    a{
      text-decoration: none;
    }

    .banner-mid-container {
      position: relative;
      width: 100%;
      left: 0;
      top: 100%;
      margin-top: -22vw;
      @include breakpoint(tablet) {
        margin-top: -102px;
      }
      height: 100%;
      z-index: 10;

      @include breakpoint(tablet-plus) {
        position: absolute;
        top: 0;
        margin-top: 0;
        @include gradient(to right , $gradient-light);
      }
    }

    &.bb-light-blue{
      .banner-mid-container {
        @include breakpoint(tablet-plus) {
          @include gradient(to right , $gradient-dark);
        }
      }

    }

    .views-field-field-banner-image-mobile,
    .views-field-field-video,
    .views-field-field-video-mobile{
      @media screen and (min-width: 540px) {
        .desktop {
          display: block !important;
        }

        .mobile {
          display: none;
        }
      }
    }

    .hide-desktop-default{
      .desktop-default{
        @include breakpoint(max-tablet-plus){
            display: none;
        }
      }
    }

    .banner-video {
      display: flex;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      text-align: center;

      video {
        height: calc(100vw * 430/360);

        @media screen and (min-width: 540px) {
          height: auto;
          width: 100vw;
        }
      }
    }

    .views-field-field-banner-image-mobile {
      @include breakpoint(max-tablet-plus){
        &::after{
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 50%;
          @include gradient("to top", $gradient-totop);
        }
      }
      img {
        height: calc(100vw * 430/360);
        width: 100vw;
        display: block;

        @media screen and (min-width: 540px) {
          height: auto;
        }
      }
    }

    .banner-bubble {
      max-width: 540px;
      margin: 0 auto;

      @include breakpoint(tablet-plus) {
        position: absolute;
        left: 40px;
        top: 6vw;
        width: 481px;
      }

      .bb-container {
        background: url(#{$theme}/images/blobs/bb-blue.svg) top left -33px no-repeat transparent;
        background-size: 118.6% 100%;
        padding: 59px 56px 68px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: 540px) {
          background-size: 100% 100%;
          background-position: 0 0;
          padding: 59px 76px 68px;
          box-sizing: border-box;
        }

        @include breakpoint(tablet-plus) {
          padding: 51px 50px 64px 87px;
        }

        h2 {
          color: #fff;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      strong {
        position: absolute;
        width: 354px;
        top: -14px;
        left: 50%;
        margin-left: -177px;
        color: #fff;
        background: url(#{$theme}/images/blobs/claim-green.svg) top center no-repeat transparent;
        background-size: 100% 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 10px 10px 14px;
        min-height: 53px;

        @include breakpoint(tablet-plus) {
          width: 374px;
          margin-left: -187px;
        }
      }

      &.bb-blue {
        p {
          color: $green;
        }
      }

      &.bb-grey {
        .bb-container {
          background-image: url(#{$theme}/images/blobs/bb-gray.svg);
        }

        p {
          color: $brown;
        }
      }

      &.bb-green {
        .bb-container {
          background-image: url(#{$theme}/images/blobs/bb-green.svg);
        }

        p {
          color: $green3;
        }

        strong {
          color: #fff;
          background-image: url(#{$theme}/images/blobs/claim-blue.svg);
        }
      }

      &.bb-brown{
        .bb-container {
          background-image: url(#{$theme}/images/blobs/bb-brown.svg);
        }
        p, h2 {
          color: #462F1E;
        }
      }
      &.bb-light-blue{
        .bb-container {
          background-image: url(#{$theme}/images/blobs/bb-light-blue.svg);
        }
        p, h2 {
          color: #005371;
        }

      }

      &.bb-dark-green{
        .bb-container {
          background-image: url(#{$theme}/images/blobs/bb-dark-green.svg);
        }
        p, h2 {
          color: #fff;
        }
      }


      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }


    }

    img {
      width: 100%;
    }
  }


  .slider__controls {
    position: absolute;
    top: calc(100vw * (721/861) - 50px);
    width: 100%;
    z-index: 12;
    @include breakpoint(tablet) {
      top: calc(100vw * (317/753) - 206px);
    }
    @include breakpoint(tablet-plus){
      display: none;
    }

    & .slider__pagination {
      text-align: center;
      margin-top: 50px;

      & .swiper-pagination-bullet {
        width: 13px;
        height: 13px;
        display: inline-block;
        background: #fff;
        opacity: .4;
        margin: 0 5px;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        -webkit-transition: opacity .5s, background-color .5s, width .5s;
        -o-transition: opacity .5s, background-color .5s, width .5s;
        transition: opacity .5s, background-color .5s, width .5s;
        -webkit-transition-delay: .2s, .2s, 0s;
        -o-transition-delay: .2s, .2s, 0s;
        transition-delay: .2s, .2s, 0s;
      }
    }

    & .swiper-pagination-clickable .swiper-pagination-bullet {
      cursor: pointer;
    }

    & .slider__pagination .swiper-pagination-bullet-active {
      opacity: 1;
      background: #ffffff;
      width: 26px;
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }

  }


}
