.view-22-v-kocke {
  .view-content {
    margin: 50px 0 40px;

    @include breakpoint(tablet-plus) {
        column-count: 3;
        column-gap: 50px;
    }
  }

  .views-row {
    background: #EBF5F9;
    color: $text;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0px 10px 40px;
    box-sizing: border-box;
    margin: 15px 0;

    @include breakpoint(tablet-plus){
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        display: inline-block;
        margin: 0 0 50px;
    }

    .views-field-bygstudio-benefity-text {
      color: #005371;
    }

    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(45%) saturate(5640%) hue-rotate(180deg) brightness(93%) contrast(101%);
    }

    .views-field-bygstudio-benefity-image {
      img {
        margin-top: 40px
      }
    }

    h4 {
      @extend .mobile-h3;
      margin: 30px 0 10px;
    }
  }
}
