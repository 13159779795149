@include breakpoint(tablet-plus){
    .page-aktivity .page-view-title,
    .page-aktivity-pre-verejnost .page-view-title{
        max-width: 1140px;
        text-align: left;
        margin: 0 auto;
        h1{
            width: 450px;
            float: left;
        }
    }
}


.view-csr-projekty{
    @extend .midc;

    @include breakpoint(tablet-plus){
        max-width: 1140px;
        margin-bottom: 65px;
    }

    .view-header{
        text-align: center;
        margin-bottom: 40px;
        p{
            @extend .lead;
        }

        .text-chevron-left{
            text-align: left;
        }
        @include breakpoint(tablet-plus){
            float: left;
            clear: both;
            text-align: left;

            p{
                margin: 0;
                /*
                @media screen and (min-width: 970px){
                    margin-left: calc((940px - min((100vw - 40px), 1140px)) / 2);
                }
                @media screen and (min-width: 1180px){
                    margin-left: calc((940px - min(100vw, 1140px)) / 2);
                }*/
                max-width: 433px;
            }
        }
    }

    .view-filters{
        @include breakpoint(tablet-plus){
            float: left;
            margin-left: 30px;
            margin-top: -51px;
            .chosen-container{
                width: calc(167px + ((100vw - 940px) / 2 )) !important;
                max-width: 330px;
            }

            @include breakpoint(desktop){
                margin-left: 50px;
            }
        }
    }

    .view-content{
        @extend .midc;
        margin-bottom: 40px;
        @include breakpoint(tablet-plus){
            clear: both;
        }
    }
    .zobrazenie{
        margin: 15px 0 0;
        .label{
            color: $text;
        }
        @include breakpoint(tablet-plus){
            .label{
                display: none;
            }
            float: right;
            margin: -25px 0 0 50px;

            @include breakpoint(desktop){
                margin-left: 65px;
            }
        }
    }

    .csr-oblast,
    .views-field-field-text-vycuc{
        display: none;
    }

    .csr-oblast{
        height: 46px;
        padding: 10px 24px;
        background-color: $green-bg;
        color: $green3;
        border-radius: 23px;
        margin-bottom: 20px;
        margin-left: 20px;
        &.noimg{
            margin: 0;
        }
    }

    .text-strucne{
        @include fontSize(18);
        @include breakpoint(tablet-plus){
            padding-right: 250px;
        }
    }
    h3{
        // @include fontSize(18);
        margin: 0 0 11px;
        @include breakpoint(tablet-plus){
            @include fontSize(28.13);
            line-height: 130%;
            padding-right: 250px;
        }

        a{
            color: $text;
            text-decoration: none;
        }
    }

    &.detailne{
        .csr-oblast{
            display: inline-block;
            @include breakpoint(tablet-plus){
                height: 38px;
                padding: 5px 24px;
            }
        }
        .views-field-field-text-vycuc{
            display: block;
            @include breakpoint(tablet-plus){
                padding-left: 298px;
            }
        }
        .views-field-field-popis{
            display: none;
        }
    }
    .views-field-field-externy-odkaz,
    .views-field-view-node{
        @extend .text-chevron-right;
        @include breakpoint(tablet-plus){
            padding-left: 298px;
        }
    }

    .views-field-field-featured-obrazok{
        position: relative;
        @include breakpoint(max-tablet-plus){
            img{
                float: right;
                margin-left: 30px;
                width: 110px;
                border-radius: 12px;
            }
        }

        @include breakpoint(tablet-plus){
            img{
                float: left;
                margin-right: 50px;
                width: 248px;
                border-radius: 12px;
                & + .csr-oblast{
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                }
            }
        }

    }
    &.detailne{
        @include breakpoint(max-tablet-plus){
            .views-field-field-featured-obrazok{
                margin-bottom: 20px;
                img{
                    float: none;
                    margin-left: 0;
                    width: 100%;
                    & + .csr-oblast{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }

            }
        }
    }
    .views-row {
        padding: 40px 0 30px;
        border-bottom: 2px solid #CCDDE3;
        @extend .clearfix;
        &:last-child{
            border-bottom: 0;
        }
    }

    .csr-vedenie-dialogu{
        .csr-oblast{
            background-color: #E3EADF;
            color: #46712D;
        }
    }

    .csr-ochrana-klimy{
        .csr-oblast{
            background-color: #D9E5EA;
            color: #005371;
        }
    }

    .csr-ferove-jednanie,
    .csr-ferove-zaobchadzanie{
        .csr-oblast{
            background-color: #F5EED5;
            color: #462F1E;
        }
    }

    .csr-podpora-zdravia{
        .csr-oblast{
            background-color: #EFF7FA;
            color: #005371;
        }
    }

    .csr-setrenie-zdrojov{
        .csr-oblast{
            background-color: #E3E2E0;
            color: #462F1E;
        }
    }
}

.buttons-switch{
    background-color: $green;
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 50px;
    margin: 5px 0 0px;
    @include breakpoint(tablet-plus){
        margin: 10px 0 0px;
    }
    .button{
        display: inline-block;
        color: #fff;
        margin: 0;
        height: 42px;
        font-weight: normal;
        text-transform: none;
        padding: 0 23.5px;
        line-height: 42px;
        @include fontSize(18);
        &:hover{
            background-color: $green2;
        }
        &.selected{
            background-color: #fff;
            color: $ctext_dark;
        }
    }
}



.pager--infinite-scroll{
    padding: 0;
    margin: 24px auto;
    text-align: center;
    li{
        list-style: none;
        margin: 0 auto;
        padding: 0;
        a{
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-icon;
            @extend .btn-chevron;
            @extend .btn-chevron-down;
        }
        .throbber{
            display: none;
        }
    }
}
.view-22-aktuality{
    .pager--infinite-scroll{
        margin: -23px auto 50px;
        @include breakpoint(tablet-plus) {
            margin-bottom: 80px;
        }
    }
}