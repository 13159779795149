body{
  margin: 0 auto;
  max-width:100vw;
  overflow-x: hidden;
}

html {
  height: 100%;
  overflow-y: scroll; /* must be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}


.container {
  margin: 0 auto;
  // outline: $debug-orange;
}


.region {
  //outline: $debug-blue;
  padding: 0;
}

.mid-container {
  width: 100%;
  position: relative;
  padding: 0 15px;
}

.full-container{
  margin: 0 -15px;
  padding: 0 15px;
}

.midc{
  max-width: 450px;
  margin: 0 auto;
  @media screen and (min-width:740px) {
    &.mid744{
      max-width: 744px;
    }
  }
  @include breakpoint(tablet-plus){
    max-width: 940px;
  }
}

.midc-744{
  max-width: 744px;
}

.midc-960{
  max-width: 960px
}
.midc-865{
  max-width: 865px
}

.midc-1140{
  @include breakpoint(tablet-plus){
    max-width: 1140px;
    margin: 0 auto;
  }
}

.content-2cols{
  @include breakpoint(tablet-plus){

  display: flex;
  justify-content: center;
  gap: 69px;
  margin-top: 60px;

    .left-content {
      max-width: 545px;
      margin: 0;

    }

    .right-content {
      max-width: 347px;
      background: $white;
      padding: 0;
    }
  }
}

.node__content{

  .text-formatted{
    p{
      margin-bottom: 25px;
    }
    li{
      margin-bottom: 10px;
    }
    ul{
      li{
        list-style-type: square;
      }
    }
      .btn{
        margin:10px auto;
      }


      h2, h3{
        margin:10px 0 15px
      }
      h4{
        margin: 25px 0 15px;
      }
  }

}

.media_embed{
  max-width: 545px;
  max-height: 309px;
  width: calc(100vw - 30px) !important;
  height: calc((309 / 545) * (100vw - 30px)) !important;
  border-radius: 12px;
  overflow: hidden;
  margin: 1.5rem auto;
  iframe{
    max-width: 545px;
    max-height: 309px;
    width: calc(100vw - 30px) !important;
    height: calc((309 / 545) * (100vw - 30px)) !important;
  }
}

.field-name-field-text-sekcie{
  img{
    margin: 2rem auto;
    border-radius: 12px;
    display: block;
  }
}
