.node-article, .node-stanovisko, .node-csr-projekt{

    article{
        margin-bottom: 65px;
        margin-top: 55px;

        @include breakpoint(tablet-plus){
            margin-top: 65px;
        }
    }

    h1{
        margin-top: 8px;
        margin-bottom: 10px;
      }

      .field-name-field-date-zverejnit{
          color: $gray3;
          margin-bottom: 25px;
      }

    .field-name-field-csr-oblast{
        text-transform: uppercase;
    }
    .field-name-field-popis{
        margin: 20px 0 18px;
    }
    .field-name-field-featured-obrazok{

        margin: 30px 0;
        img{
            display: block;
            border-radius: 12px;
        }
    }



    .field-name-field-galeria{
        text-align: center;
        img{
            display: block;
            border-radius: 12px;
            margin: 0 auto;
            max-height: 60vw;
            width: auto;
              @include breakpoint(tablet-plus){
                max-height: 500px;
                width: auto;
                }
        }
        @include breakpoint(tablet-plus){
            overflow: hidden;
            margin: 40px 0;

        }

        &.oneimg{
            img{
                max-height: none;
            }
        }
    }

    .field-name-field-text-sekcie{
        margin: 0;
        padding: 0;
        border: 0;
        img{
            height: auto !important;
        }
        /*
        em{
            display: block;
            margin: 15px 0;
        }
        */
    }

    .field-name-field-text-sekcie + .field-name-field-detail-vyber .node-article h1,
    .field-name-field-featured-obrazok + .field-name-field-obsah .field-name-field-detail-vyber .node-article h1{
        margin-top: 48px;
    }

    .field-name-field-obsah{
        @include breakpoint(tablet-plus) {
            margin-bottom: 30px;
            .field-name-field-headline-sekcie-h1{
                margin-top: 40px;
            }

            .item0{
                .field-name-field-headline-sekcie-h1{
                    margin-top: 0px;
                }
            }
        }

        table{
            width: 100%;
            td{
                padding: 7px 4px;
                text-align: left;
                //border-bottom: 1px solid $gray5;
            }

            tr:nth-child(2n){
                td{
                    background-color: #f5fafc;
                }
            }
        }
    }



    .btn-back{
        @include breakpoint(tablet-plus) {
            margin: 65px 0 40px;
            width: 302px;
            height: 67px;
            line-height: 38px;
            border-radius: 35px;
            @include fontSize(18);
            span{
                padding-left: 4px;
            }
            &::before{
                margin-left: -15px;
                margin-right: 5px;
                margin-top: 10px;
            }
        }
    }

}
.node-article, .node-csr-projekt{

    .swipe-gallery{
        position: relative;
    }

    --swiper-navigation-size: 22px;
    --swiper-theme-color: #fff;

    .swiper-wrapper{
        align-items: center;
    }
    .swiper-button-next{
        padding: 20px;
        border-radius: 50px;
        background: $green;;
      &::after{
        text-shadow: -1px 0 0 #fff;
        margin-left: 4px;
      }
    }
    .swiper-button-prev{
        padding: 20px;
        border-radius: 50px;
        background: $green;
      &::after{
        text-shadow: 1px 0 0 #fff;
        margin-right: 4px;
      }
    }

    .photoswipe-gallery{
        text-align: center;
        a{
            display: inline-block;
        }
    }

    .field-name-field-galeria{
        padding: 0;
        margin: 20px 0;
        @include breakpoint(tablet-plus){
            margin: 40px 0;
        }

        li{
            list-style: none;
        }
    }
}

.node-csr-projekt{
    .field-name-field-csr-oblast{
        margin-top: 55px;
        @include breakpoint(tablet-plus){
            margin-top: 65px;
        }
    }
    article{
            margin-top: 0;
    }
}
#aktuality-3col{
    margin: 50px auto 80px;
    @include breakpoint(tablet-plus){
        margin-bottom: 0;
        margin-top: 65px;
    }
}
