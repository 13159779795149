.node-csr-oblast {

  .oblast-topimg {
    display: block;
    width: 100vw;
    max-width: 684px;
    margin: 0 auto;
  }

  .title-header {
    margin-top: -56px;
    position: relative;

    @include breakpoint(tablet-plus){
      position: absolute;
      margin-top: 0;
      top: 71px;
      max-width: 1042px;
      left: 50%;
      margin-left: max(calc(-100vw / 2 + 20px), -521px);
    }

    @include breakpoint(tablet-plus){
      img{
        width: 346px;
      }
    }

    h1 {
      position: absolute;
      top: 46px;
      left: 52px;
      color: #fff;
      margin: 0;
      width: 1px;

      @include breakpoint(tablet-plus){
        top: 19px;
        left: 68px;
        @include fontSize(40);
        line-height: 122%;
      }
      &.title-ferove-jednanie {
        color: #462F1E;
      }

      &.title-podpora-zdravia {
        color: #005371;
      }
    }
  }

  .text {
    margin: 38px auto 50px;
    max-width: 740px;
    @include breakpoint(tablet-plus){
      margin: 60px auto 90px;
    }
  }

  #uspechy {
    padding: 50px 15px 130px;
    text-align: center;
    @extend .windmill;
    margin-bottom: 50px;

    @include breakpoint(tablet-plus){
      padding: 80px 15px 280px;
    }

    h2 {
      margin: 0 0 30px;
      color: $text;
    }

    .lead {
      color: $blue;
      max-width: 680px;
      @include breakpoint(tablet-plus){
        margin: 30px auto 60px;
        @include fontSize(18);
      }
    }

    .field-name-field-doterajsie-uspechy {
      margin-top: 40px;

      @include breakpoint(tablet-plus){
        column-count: 3;
        column-gap: 50px;

        .field-item{
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          break-inside: avoid;
          display: inline-block;
          page-break-inside: avoid;
          @supports (-webkit-column-break-inside: avoid) {
            display: block;
          }
        }
      }

      .uspechy-box {
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        border-radius: 12px;
        margin: 0 0 20px;
        @include breakpoint(tablet-plus){
          margin: 0 0 30px;
        }
        break-inside: avoid;

        h4 {
          margin: 0px 0 10px;
        }

        .field-name-bygstudio-benefity-image {
          margin-bottom: 30px;
        }
      }
    }

    #zavazky{
        h2{
            margin-top: 50px;
        }
    }
    .field-name-field-zavazky{
        margin-top: 30px;

        @include breakpoint(tablet-plus){
          column-count: 2;
          column-gap: 50px;

          @include breakpoint(desktop){
            display: flex;
            justify-content: center;
            &.more-than-three{
              columns: auto 4;
              display: block;
              .field-item{
                display: grid;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                break-inside: avoid;
                margin-bottom: 50px;
                .field-collection-view{
                  margin: 0;
                  .field-item{
                    margin: 0;
                  }
                }
              }
            }
            gap: 50px;
          }

          .field-item{
            width: 100%;
            @include breakpoint(desktop){
              max-width: 346px;
            }
            margin: 0;
            box-sizing: border-box;
            display: inline-block;
          }
        }

        .field-collection-view{
            padding: 40px 18px;
            margin: 0;
            box-sizing: border-box;
            background: $green3;
            color: #fff;
            border-radius: 12px;
            margin-top: 20px;
            border: 0;
            @include breakpoint(tablet-plus){
              margin: 25px 0;
            }

            .field-name-field-zavazky-nadpis{
                @extend .desktop-h4;
                margin: 30px 0 10px;
            }
        }
    }
    .field-collection-container{
        border: 0;
    }

    &.ochrana-klimy {
      background: #F5FAFC;

      .uspechy-box {
        background: #BDDDE9;
      }

      h4 {
        color: $text;
      }

      .field-name-bygstudio-benefity-text {
        color: #005371;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(16%) sepia(45%) saturate(5640%) hue-rotate(180deg) brightness(93%) contrast(101%);
      }

      .field-name-field-zavazky{
        .field-collection-view{
            background: $blue;
        }
      }
    }

    &.zachovanie-biodiverzity {
      background: #F4F5E7;

      .uspechy-box {
        background: $green;
      }

      h4 {
        color: #fff;
      }

      .field-name-bygstudio-benefity-text {
        color: #fff;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(35%) sepia(8%) saturate(3564%) hue-rotate(54deg) brightness(101%) contrast(81%);
      }
    }

    &.setrenie-zdrojov {
      background: #F4F5E7;

      .uspechy-box {
        background: #918D82;
      }

      h4 {
        color: #fff;
      }

      .field-name-bygstudio-benefity-text {
        color: #fff;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(84%) sepia(9%) saturate(2053%) hue-rotate(21deg) brightness(89%) contrast(85%);
      }
    }


    &.ferove-jednanie,
    &.ferove-zaobchadzanie {
      background: #F9F5E5;

      .uspechy-box {
        background: #D5BA55;
      }

      h4 {
        color: #fff;
      }

      .field-name-bygstudio-benefity-text {
        color: #fff;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(16%) sepia(49%) saturate(544%) hue-rotate(343deg) brightness(95%) contrast(90%);
      }
    }

    &.podpora-zdravia {
      background: #F9F5E5;
      color: $blue;

      .uspechy-box {
        background: #BDDDE9;
      }

      h4 {
        color: $blue;
      }

      .field-name-bygstudio-benefity-text {
        color: $blue;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(42%) sepia(9%) saturate(2249%) hue-rotate(54deg) brightness(91%) contrast(98%);
      }
    }


    &.vedenie-dialogu {
      background: #F9F5E5;
      color: $blue;

      .uspechy-box {
        background: #B4BE5D;
      }

      h4 {
        color: #fff;
      }

      .field-name-bygstudio-benefity-text {
        color:#fff;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(42%) sepia(9%) saturate(2249%) hue-rotate(54deg) brightness(91%) contrast(98%);
      }
    }
  }
}

#aktivity{
    margin-bottom: 65px;

    h2{
        text-align: center;
        margin-top: 110px;
        @include breakpoint(tablet-plus){
          margin-top: 164px;
        }
    }

    @include breakpoint(tablet-plus){

    .csr-oblast{
      display: none !important;
    }

    .text-strucne{
      padding-right: 0;
    }
  }
    .more-link{
        text-align: center;
        a{
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-icon;
            @extend .btn-chevron;
            margin-bottom: 40px;
            @include breakpoint(tablet-plus){
              margin: 60px auto 23px;
            }

        }
    }
}

#aktuality{
  margin-top: 50px;
  margin-bottom: 20px;
  @include breakpoint(tablet-plus){
    margin-bottom: 50px;
    margin-top: 80px;
    h2{
      margin-bottom: 45px;
    }
  }
  .more-link{
    text-align: center;
  }
}

#stanoviska{
  background: #F4F5E7;
}

#kariera{
  text-align: center;
  background: #F4F5E7;
  padding: 47px 15px 54px;
  @include breakpoint(tablet-plus){
    padding: 70px 15px;

    .midc{
      display: flex;
      justify-content: space-between;
      max-width: 1040px;
      gap: 12%;
      .right{
        text-align: left;
      }
      .lead{
        @include fontSize(18);
      }
    }
  }
  img{
    margin: 0 auto;
    display: block;
    width: 267px;
    @include breakpoint(tablet-plus){
      width: 397px;
    }
  }
  h2{
    padding:  0 25px;
    @include breakpoint(tablet-plus){
      padding: 0;
    }
  }
}
