#section-v-kocke{
    background: #F4F5E7;
    padding-top: 128px;
    text-align: center;

    .left{
        img{
            width: 250px;
            height: auto;
            margin: 0 auto;
            display: inline-block;
        }
    }

    .hint-text{
        text-transform: uppercase;
        margin: 45px 0 20px;
        @include breakpoint(tablet-plus){
            margin: 20px 0;
        }
    }
    h2{
        margin: 20px auto;
        @extend .desktop-h3;
    }
    p{
        margin: 0 auto;
    }
    .btn-primary{
        margin: 40px 0 12px;
    }
    .btn-secondary{
        margin: 12px 0 53px;
    }

    .row{
        @include breakpoint(tablet-plus){
            max-width: 1020px;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            gap: 122px;
            justify-content: space-between;
            position: relative;
            .left{
                text-align: left;
                padding: 0;
                flex: 1 1 0px;
                img{
                    width: 384px;
                    float: right;
                }
            }
            .right{
                flex: 1 1 0px;
                max-width: 484px;
                text-align: left;
            }
            p.lead{
                @include fontSize(18);
                line-height: 150%;
            }
        }
    }
}