// Font Stacks/Sizes

//Normal - 300; Semibold 600; Extra Bold 800

$base-font-size: 18; // Pixel value only, no px unit
$base-line-height: 1.428571429; // Pixel value only, no px unit

$base-font:       'LidlFont', sans-serif;
$times-new-roman: "Times New Roman", Times, Georgia, serif;
$times:           Times, "Times New Roman", Georgia, serif;
$georgia:         Georgia, "Times New Roman", serif;
$verdana:         Verdana, Tahoma, sans-serif;
$tahoma:          Tahoma, Verdana, sans-serif;
$helvetica:       Helvetica, Arial, sans-serif;
$arial:           Arial, Helvetica,  sans-serif;
$courier:         "Courier New", monospace, sans-serif;
