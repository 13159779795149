// Compile Sass


// Base Mixins
@import "base/mixins";

//Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

// Base Variables
@import "base/variables/breakpoints";
@import "base/variables/colors";
@import "base/variables/debugging";
@import "base/variables/fonts";
@import "base/variables/units";


// Base Styles
@import "base/accessibility";
@import "base/base";
@import "base/cookie";
@import "base/typography";
@import "base/keyframes";
@import "base/forms";

// Styles
@import "styles/admin";
@import "styles/template";
@import "styles/layout";
@import "styles/frontpage";
@import "styles/subpages";

// Component Styles
@import "styles/regions/blocks";
@import "styles/regions/header";
@import "styles/regions/hamburger";
@import "styles/regions/footer";
@import "styles/regions/slick";

//Blocks
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_search.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_section-aktuality.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_section-csr-oblast.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_section-ocenenia.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_section-uspechy.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/blocks/_section-v-kocke.scss";


//Pages
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/pages/_aktivita_kampan.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/pages/_article.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/pages/_csr-oblast.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/pages/_webform.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/pages/ozvdr.scss";


//Views
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-aktuality.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-banner-home.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-csr-oblasti.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-pinned-aktivita.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-stanoviska.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-22-v-kocke.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-csr-projekty.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-ocenenia.scss";
@import "/shared/httpd/spolocenskazodpovednost/web/sites/all/themes/bygstudio/sass/styles/views/_view-search.scss";



// Other
@import "print";
