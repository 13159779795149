.view-search{
    @extend .midc;
    @extend .midc-744;

    margin-bottom: 40px;
    @include breakpoint(tablet-plus){
      margin-top: 40px;
      margin-bottom: 90px;
    }
    .view-header{
      margin-bottom: 30px;
    }
    .pager--infinite-scroll li a{
      margin-bottom: 0;
    }
    .views-row{
        margin:15px 0;
        .views-field-title{
          display: inline;
          @include breakpoint(tablet-plus){
            display: block;
            padding-left: 90px;
          }
        }
        a{
            color: $text;
            text-decoration: none;
        }
        .views-field-type{
          font-size: 0.8rem;
          display: inline-block;
          vertical-align: middle;
          background-color: #fff4d3;
          color: #462f1e;
          padding: 0.2em 0.5em;
          border-radius: 15px;
          margin-right: 0.5em;
          line-height: 130%;

          &.Aktivita{
            background-color: #eff7fa;
            color: #005371;
          }

          &.Aktualita{
            background-color: #f4f5e7;
            color: #46712d;
          }

          @include breakpoint(tablet-plus){
            float: left;
            margin-bottom: 1em;
            margin-top: 2px;
            clear: both;
          }

        }
    }
}