.ocenenia{
   .field-name-field-main-img{
       @extend .desktop;
   }
}

#ozvdr-link{
    max-width: 744px;
    margin: 0 auto;
    display: block;
}

#suvisiace-aktuality{
    margin: 50px auto 30px;

    @include breakpoint(tablet-plus){
        h3.block-title{
            margin: 80px auto 40px;
            max-width: 1140px;

        }
    }
}

.node-7675{
    text-align: center;
    p{
        @extend .lead;
        @include breakpoint(tablet-plus) {
            @include fontSize(18);
        }
        &.midc570px{
            max-width: 570px;
            margin: 0 auto;
        }
    }
    .field-item{
        div{
            display: flex;
            flex-direction: column;
            gap: 50px;
            margin: 60px 0 30px;
            @include breakpoint(max-tablet-plus){
                p:first-child{
                    order: 2;
                }
            }

            @include breakpoint(tablet-plus){
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                max-width: 780px;
                margin: 50px auto 70px;
                flex-direction: row;
            }
        }
        a.mailto{
           width: 100%;
           max-width: 446px;
           margin: 0 auto;
           background: url(#{$theme}/images/blobs/grey-strip-mobile.svg) center center no-repeat transparent;
           background-size: 100% 100%;
           min-width: 320px;
           font-weight: bold;
           color: $text;
           display: block;
           padding: 25px 0;
           text-decoration: none;
           position: relative;
           &::after{
               content: '';
               width: 75px;
               height: 75px;
               background: url(#{$theme}/images/blobs/envelope.svg) center center no-repeat transparent;
                position: absolute;
                left: 50%;
                margin-left: -37.5px;
                top: -50px;
           }

           @include breakpoint(tablet-plus){
            background: url(#{$theme}/images/blobs/grey-strip.svg) center center no-repeat transparent;
            background-size: 100% 100%;
                &::after{
                    width: 60px;
                    height: 60px;
                    background-size: 100% 100%;

                    left: 20px;
                    top: 50%;
                    margin: -40px 0 0 0;
                }
           }
        }
    }

}

.node-type-csr-v-kocke{
    .page-right{
        iframe{
            min-height: 51.2vw;
            @include breakpoint(tablet-plus) {
                min-height: 0;
            }


        }
    }
}

@include breakpoint(tablet) {}

@include breakpoint(tablet-plus) {}

@include breakpoint(desktop) {}

@include breakpoint(desktop-plus) {}
