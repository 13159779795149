// Base HTML Styles

@use "sass:math";

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

html {
  font-size: 100% * math.div($base-font-size, 16);
  line-height: $base-line-height;
  min-height: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
object,
figure,
input,
svg {
  max-width: 100%;
  height: auto;
}

a {
  color: $primary;
}

.button, .btn {
  height: 51px;
  height: rem(51);
  @include fontSize(15);
  line-height: 1.6;
  font-weight: 700;

  border-radius: 26px;
  box-sizing: border-box;
  padding: 14px 30px 13px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;

  transition: all 0.1s;
	-webkit-transition: all 0.1s;

  position: relative;
  text-align: center;
  width: auto;
  margin: 30px auto;
  &.btn-icon{
    width: auto;
    display: inline-block;
    &::after{
      content: '';
      width: 20px;
      height: 20px;
      background: url(#{$theme}/images/icons/chevron-right.svg) center center no-repeat transparent;
      padding-left: 12px;
      margin-top: 1px;
      float: right;
    }
  }

  &.btn-icon-left{
    width: 222px;
    display: flex;
    justify-content: center;
    &::before{
      content: '';
      width: 20px;
      height: 20px;
      background: url(#{$theme}/images/icons/chevron-left.svg) left center no-repeat transparent;
      padding-right: 10px;
      margin-top: 1px;
      margin-left: -5px;
      float: left;
    }
  }

  &.btn-back{
    &::before{
      background: url(#{$theme}/images/icons/chevron-left.svg) left center no-repeat transparent;
    }
  }

  &.btn-chevron{
    &::after{
      background: url(#{$theme}/images/icons/chevron-right.svg) center center no-repeat transparent;
    }
  }

  &.btn-chevron-secondary{
    &::after{
      background: url(#{$theme}/images/icons/chevron-right-green.svg) center center no-repeat transparent;
    }
  }


  &.btn-close{
    &::after{
      background: url(#{$theme}/images/icons/close.svg) center center no-repeat transparent;
    }
  }

  &.btn-chevron-down{
    &::after{
      background: url(#{$theme}/images/icons/chevron-down-white.svg) center center no-repeat transparent;
    }
  }

  &.btn-external{
    &::after{
      background: url(#{$theme}/images/icons/external-link-green.svg) center center no-repeat transparent;
    }
  }

  &.btn-icon-grid{
    &::after{
      background: url(#{$theme}/images/icons/grid.svg) center center no-repeat transparent;
    }
  }



  &.btn-green,
  &.btn-primary {
    break-inside: avoid;
    color: $white;
    background: $green;
    &:hover{
      background: $green2;
    }
  }

  &.btn-white {
    color: $gray2;
    background: $white;
    border: 1px solid $gray5;
    box-shadow: 0px 3px 0px $gray5;
    &:hover {
      box-shadow: 0px 2px 0px darken($white, 20%);
    }
  }

  &.btn-transparent,
  &.btn-secondary {
    color: $green3;
    background: transparent;
    border: 3px solid $green3;
    padding: 11px 38px 10px;
    &:hover {
      background: $green15;
    }
  }

  &.btn-medium{
    @include fontSize(14);
    line-height: 135%;
    padding: 15px 20px;
    border-radius: 20px;
    height: 49px;
    width: auto;

    @include breakpoint(tablet-plus){

      @include fontSize(16);
      line-height: 135%;
      height: 52px;
    }
  }

  &.btn-rounded{
    border-radius: 25px
  }

  &.btn-small{
    @include fontSize(12);
    line-height: 16px;
    padding: 9px 10px 8px;
    height: 32px;
    border-radius: 10px;
    img{
      height: 12px;
      width: 12px;
      display: inline;
      margin-right: 7px;
      margin-top: 2px;
      margin-bottom: -1px;
    }
  }

}

.btn-red{
  @extend .btn-primary;
}

blockquote{
  padding: 1px 35px;
  border-left: 2px solid #BDDDE9;
  margin: 0;
  margin-bottom: 2rem;
}


.text-chevron-right{
  margin-top: 10px;
  a{  display: block;
      &::after{
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-left: 5px;
          background: url(/sites/all/themes/bygstudio/images/icons/chevron-right-green-light.svg) center top 4px no-repeat transparent;
          margin-right: -20px;
      }
  }
}

.a-chevron-right{
   display: block;
      &::after{
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-left: 5px;
          background: url(/sites/all/themes/bygstudio/images/icons/chevron-right-blue.svg) center top 1px no-repeat transparent;
          margin-right: -20px;
          background-size: 16px;
      }
}


.text-chevron-left{
  margin-top: 20px;
  display: block;

      &::before{
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;

          background: url(/sites/all/themes/bygstudio/images/icons/chevron-left-green-light.svg) center top 1px no-repeat transparent;
          background-size: 16px 16px;
          margin-right: 5px;
      }
}
.scrolltable {
  max-width: 100%;
  overflow: auto;
}

table{
  max-width: 100%;
}

.download-links{
  span{
    white-space: nowrap;
  }
}
.hidden{
  display: none !important;
}

/* Margins */

.mb40{
  margin-bottom: 40px;
}

/* Colors */

.green{
  color: $green;
}

.gray3{
  color: $gray3;
}
/* Responsive */
@include breakpoint(max-tablet-plus) {
  .desktop {
    display: none !important;
  }
}
@include breakpoint(tablet-plus) {

  .mobile {
    display: none !important;
  }
}

