#section-aktuality{
    background: #F4F5E7;
    padding: 43px 0 0;
    text-align: center;
    color: $ctext_dark;
    @include breakpoint(tablet-plus){
        padding: 80px 0 0;
    }
    h2{
        margin: 0 auto 43px auto;
        @include breakpoint(tablet-plus){
            margin-bottom: 58px;
        }
    }
}