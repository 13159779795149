.views-22-aktuality,
.page-node-131{
    .view-22-aktuality{
        @include breakpoint(desktop){
            max-width: 1140px;
            .view-content{
                max-width: 1140px;
                .views-row{
                    width: 347px;
                    width: calc(33% - 35px);
                }
            }
        }
    }
}

.view-22-aktuality{
    @include breakpoint(tablet){
        max-width: 450px;
        margin: 0 auto;
    }
    @include breakpoint(tablet-plus){
        max-width: 940px;
        &.view-display-id-block_6{
            margin-top: 60px;
            p{
                margin: 0 ;
            }
            h3{
                margin-top: 30px;
            }
        }

        &.view-display-id-block_5,
        &.view-display-id-block_7{
            max-width: 1140px;
            .view-content{
                max-width: 1140px;
                .views-row{
                    width: 347px;
                    width: calc(33% - 35px);
                }
            }
        }
    }

    .view-filters{
        max-width: 375px;
        margin: 0;
        @include breakpoint(tablet-plus){
            margin-bottom: 40px;
        }
    }

    p.lead.center{
        width: 100%;
    }

    @include breakpoint(tablet-plus){
        .view-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            gap: 57px;
            max-width: 940px;
            margin: 0 auto;
            .views-row{
                width: calc(50% - 25px);
                margin: 0 0 90px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }

    .views-row{
        text-align: left;
        margin: 60px auto;

        img{
            display: block;
            border-radius: 12px;
            background-color: #fff;
        }

        h3{
            a{
                text-decoration: none;
                color: $ctext_dark;
            }
            margin-bottom: 0;
            @include breakpoint(tablet-plus){
                @include fontSize(22);
                line-height: 130%;
                margin: 30px 0 0px;
            }
        }

        .views-field-field-date-zverejnit{
            color: $gray3;
            @include fontSize(16);
            margin-bottom: 10px;
            @include breakpoint(tablet-plus){
                margin-bottom: 0;
            }

        }

        .views-field-view-node{
            text-align: center;
            @include breakpoint(tablet-plus){
                text-align: left;
               // align-self: flex-end;
            }
            a{
                @extend .btn;
                @extend .btn-secondary;
                @extend .btn-icon;
                @extend .btn-chevron-secondary;
                width: 222px;
                margin-bottom: 0;
                margin-top: 15px;
                @include breakpoint(tablet-plus) {
                    margin-top: 20px;
                }
            }
        }
        @include breakpoint(tablet-plus) {
            .views-field-field-featured-obrazok{
                display: none;
            }
        }
    }

    .more-link{
        text-align: center;
        @include breakpoint(tablet-plus) {
            margin-top: -23px;
        }
        a{
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-icon;
            @extend .btn-chevron;
            width: 222px;
            margin: 0;

            margin-bottom: 60px;
            @include breakpoint(tablet-plus) {
             margin-bottom: 98px;
            }
         }
    }

    &.view-display-id-block_7{
        .more-link{
            a{
               width: 300px;
               margin-bottom: 60px;
               @include breakpoint(tablet-plus) {
                margin-bottom: 98px;
               }
            }
        }
    }

    &.view-display-id-page_2{
        .view-content{
            p.lead.center{
                display: none;
                &:first-child{
                    display: block;
                }
            }
        }
    }
}