#views-exposed-form-search-page {
  text-align: center;
  #edit-search-api-views-fulltext-wrapper {
    label {
      display: none;
    }
  }

  .views-submit-button {
    text-align: center;
  }

  #edit-submit-search {
    @extend .btn;
    @extend .btn-primary;
    margin: 25px auto;
    border: 0;
    width: 222px;
  }
}

#search-content{
  text-align: center;
    h1{
        margin: 40px 0;
    }

    .close-search{
      position: absolute;
      top: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      background: url(#{$theme}/images/icons/close.svg) center center no-repeat transparent;
      background-size: 100%;
      cursor: pointer;
    }

    .clear{
      position: absolute;
      right: 15px;
      top: 15px;
      width: 30px;
      height: 30px;
      background: url(#{$theme}/images/icons/close-blue.svg) center center no-repeat transparent;
      @include opacity(0);
      transition: all .3s ease-in;
      &.visible{
        @include opacity(1);
      }
    }

    ul[data-sa-theme="basic-green"].ui-menu{
      min-width: 300px;
    }
}

.term-list{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 60px;
  a{
    color: #30495F;
    padding: 4px 16px;
    height: 34px;
    border-radius: 17px;
    border: 2px solid #CCDDE3;
    box-sizing: border-box;
    display: inline-block;
    @include fontSize(15);
    margin: 0 !important;
    &:hover{
      background-color: #CCDDE3;
    }
  }
}
.views-exposed-form {
  position: relative;

  .search-input,
  #edit-search-api-views-fulltext {
    border: 2px solid #CCDDE3;
    box-sizing: border-box;
    border-radius: 25px;
    height: 50px;
    background: url(#{$theme}/images/search.svg) left 20px center no-repeat transparent !important;
    padding-left: 50px;
    padding-right: 40px;
    @include fontSize(18);
    @include transition(all .4s ease);
    outline: 0;
    max-width: 545px;
    width: 100%;
    font-family: 'LidlFont', sans-serif;


    &.ui-autocomplete-showing {
      border-radius: 25px 25px 0 0;
    }
  }

  .ui-autocomplete {
    @include transition(all .4s ease);
    top: 52px;
    border-radius: 0 0 25px 25px;
    border-color: #C5C4C5;
    border-width: 2px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100% !important;
    font-family: 'LidlFont', sans-serif;


    .ui-autocomplete-field-group {
      background: #f4f4f4;
      display: block;
      width: 100%;
      text-align: left;
      padding: 6px 15px;
      right: 0;
      @include fontSize(14);

      &.ui-menu-divider {
        padding: 0;
        display: none;

        &+.ui-menu-item-first {
          .ui-autocomplete-fields {
            padding: 5px 15px;
            font-size: 1.4em;
          }
        }
      }
    }

    li {
      a {
        margin-bottom: 0 !important;

        &.ui-state-focus {
          border-radius: 0;
          border: 0;
          background: #F4F5E7;
        }
      }
    }
  }
  .ui-autocomplete{
    li.ui-menu-item{
      cursor: pointer;
      a{
        padding: 5px 3px !important;
        display: block;
      }
    }
  }

}

